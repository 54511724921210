import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    map,
    of,
    switchMap,
    take,
    tap,
} from 'rxjs';

import { NavigationService } from 'app/core/navigation/navigation.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { VALUE_SET_CODE_CONSTANTS, VALUE_SET_DETAILS_CODE_CONSTANTS } from 'app/shared/constants/value-set.contants';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ListService {
    private _listView: BehaviorSubject<any> = new BehaviorSubject(null);
    private _listData: BehaviorSubject<any> = new BehaviorSubject(null);
    private _listFields: BehaviorSubject<any> = new BehaviorSubject(null);
    private _listActions: BehaviorSubject<any> = new BehaviorSubject(null);
    private _advancedFilters: BehaviorSubject<any> = new BehaviorSubject(null);
    private allListData = [];
    private allListFields = [];

    /**
     * Constructor
     */
    constructor(
        private _commonService: CommonService,
        private _navigationService: NavigationService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for list view
     */
    get listView$(): Observable<any> {
        return this._listView.asObservable();
    }

    /**
     * Getter for list data
     */
    get listData$(): Observable<any> {
        return this._listData.asObservable();
    }

    /**
     * Getter for list fields
     */
    get listFields$(): Observable<any> {
        return this._listFields.asObservable();
    }

    /**
     * Getter for list fields
     */
    get listActions$(): Observable<any> {
        return this._listActions.asObservable();
    }

    /**
     * Getter for menu permissions
     */
    get permissions$(): Observable<any> {
        return this._navigationService.currentMenu$;
    }

    /**
     * Getter for menu permissions
     */
    get advancedFilters$(): Observable<any> {
        return this._advancedFilters.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get list view
     *
     * @param list_id
     * @returns
     */
    getListView(list_id: string): Observable<any> {
        return this._commonService
            .getDataByField(MODELS_CONSTANTS.LIST_VIEW, '_id', list_id)
            .pipe(
                map((response: IResponse<any>) => {
                    if (response?.status === 200 && response?.data?.length) {
                        this._listView.next(response?.data?.[0] || {});
                    } else {
                        this._listView.next(null);
                    }
                    this._listData.next(null);
                    this._listFields.next(null);
                    return response;
                })
            );
    }

    /**
     * Get List Data
     *
     * @param api_id
     * @param component_data
     * @returns
     */
    getListData(api_id: string, component_data: any): Observable<any> {
        return this._commonService
            .getDataByField(MODELS_CONSTANTS.API_REGISTRIES, '_id', api_id)
            .pipe(
                map((response: IResponse<any>) => {
                    if (response?.status === 200 && response?.data?.length) {
                        return response?.data?.[0];
                    } else {
                        return null;
                    }
                }),
                switchMap(async (api) => {
                    if (api) {
                        const resData =
                            await this._commonService.executeApiMethod(
                                api,
                                component_data
                            ); 

                        this.allListData = resData?.data || [];
                        return new Promise<any>((resolve) => {
                            setTimeout(async () => {
                                let listData = await this.idMapping();
                                this._listData.next(listData || []);
                                resolve(listData || []);
                            }, 500);  
                        });
                    } else {
                        this._listData.next([]);
                        return [];
                    }
                })
            );
    }

    async idMapping() {
        const promises = this.allListFields.map(async (field) => {
            if (field.field_component_type === VALUE_SET_DETAILS_CODE_CONSTANTS.COMPONENT_TYPE_MODULE_DATA_PICKER) {
                try {
                    const response = await this._commonService.getAllData(field.field_format).toPromise();
                  if (response?.status === 200 && response?.data?.length) {
                    let dynamicList = response?.data;
                    this.allListData.forEach((list) => {
                      if (Array.isArray(list[field.field_name])) {
                        list[field.field_name] = list[field.field_name].map((id) => {
                          let data = dynamicList.find((obj) => obj._id == id);
                          return data ? data[field.field_showing] : id;
                        });
                      } else {
                        let data = dynamicList.find((obj) => obj._id == list[field.field_name]);
                        if (data) {
                          list[field.field_name] = data[field.field_showing];
                        }
                      }
                    });
                }
            }
            catch (err) {
                console.error(err);
            }
        }
    });
        await Promise.all(promises);
        this.checkFilters();
        this.codeFormatting();
        return this.allListData;
    }

    async codeFormatting() {
        if(this.allListFields.length && this.allListData.length) {
            this.allListFields.forEach((item) => {
                if(item.field_component_type === VALUE_SET_DETAILS_CODE_CONSTANTS.COMPONENT_TYPE_CODE && item.field_showing) { 
                    this.allListData.forEach((list)=> {
                        const fieldName = item.field_name;
                        const fieldValue = list[fieldName];
                        const suffix = ` - ${list[item.field_showing]}`;
                        if (!fieldValue.includes(suffix)) {
                            list[fieldName] = fieldValue + suffix;
                        }
                    })
                }
            })
        }
    }

    checkFilters() {
        if(this.allListFields.length && this.allListData.length) {
            this.allListFields.forEach((item) => {
                if(item.field_filter_yn === true && item.field_filter_type == VALUE_SET_DETAILS_CODE_CONSTANTS.FILTER_TYPE_ASCENDING) {   //Ascending Filter
                    this.allListData = this.allListData.sort((a, b) => {
                        const fieldA = a[item.field_name]?.toString().toLowerCase();
                        const fieldB = b[item.field_name]?.toString().toLowerCase();
                        if (fieldA < fieldB) return -1;
                        if (fieldA > fieldB) return 1;
                        return 0;
                    });
                }
                if(item.field_filter_yn === true && item.field_filter_type == VALUE_SET_DETAILS_CODE_CONSTANTS.FILTER_TYPE_DESCENDING) {   //Descending Filter
                    this.allListData = this.allListData.sort((a, b) => {
                        const fieldA = a[item.field_name]?.toString().toLowerCase();
                        const fieldB = b[item.field_name]?.toString().toLowerCase();
                        if (fieldA < fieldB) return 1;
                        if (fieldA > fieldB) return -1;
                        return 0;
                    });
                }
                
            })
        }
    }

    /**
     * Get list fields
     *
     * @param listViewSetup
     * @returns
     */
    getListFields(listViewSetup: any): Observable<any> {
        return this._commonService
            .getDataByField(
                MODELS_CONSTANTS.LIST_FIELDS,
                'list_view_id',
                listViewSetup._id
            )
            .pipe(
                switchMap(async (response: IResponse<any>) => {
                    this._listFields.next(response?.data || []);
                    this.allListFields = response?.data || [];
                    return response?.data || [];
                })
            );
    }

    searchListFields(query: any) {
        query = query?.toLowerCase();
        const filterKeys = this.allListFields;
        const filteredListFields = this.allListData.filter((e) => {
            let isMatched = false;
            for (let key of filterKeys) {
                const fieldValue = e?.[key?.field_name];
                if (key?.field_name != 'status') {
                    if (typeof fieldValue === 'string' && fieldValue.toLowerCase()?.includes(query)) {
                        isMatched = true;
                        break;
                    } else if (typeof fieldValue === 'string' && fieldValue.includes(query)) {
                        isMatched = true;
                        break;
                    } else if (Array.isArray(fieldValue) && fieldValue.some(val => typeof val === 'string' && val.toLowerCase()?.includes(query))) {
                        isMatched = true;
                        break;
                    }
                }
            }
            return isMatched;
        });
        this._listData.next(filteredListFields);
        return of(filteredListFields);
    }

    searchByFilter(list: any, filter_field: any) {
        const filteredListFields = [];
        let fetchList;
        if(list.length) {
            list.forEach(item => {
                fetchList = [];
                fetchList = this.allListData.filter((element) => {
                    if (filter_field?.type == 'valueset' && list[0]?.vs_code == 'STATUS' && (item.vsd_code == 0 || item.vsd_code == 1)) {
                        return element[filter_field?.name] == 0 || element[filter_field?.name] == 1;
                    } else if (filter_field?.type == 'valueset') {
                        if(item.vsd_code == 0) {
                            return !element[filter_field?.name] || element[filter_field?.name] == item.vsd_code;
                        } else {
                            return element[filter_field?.name] == item.vsd_code;
                        }
                    } else {
                        return element[filter_field?.name] == item[filter_field?.field];
                    }
                });
                if(fetchList.length) {
                    fetchList.forEach(x => {
                        filteredListFields.push(x);                            
                    });
                }
            });
            this._listData.next(filteredListFields);
            return of(filteredListFields);
        } else {
            this._listData.next(this.allListData);
            return of(this.allListData);
        }
    }
    

    filterByType(value: any) {
        let ctrlValue = new Date();
        if(value == 'today') {
        const filteredListFields = this.allListData.filter(m => {
            return this.dateFormat(m.createdAt) == this.dateFormat(ctrlValue)
          });

        this._listData.next(filteredListFields);
        return of(filteredListFields);
        } else if(value == 'yesterday') {
            ctrlValue.setDate(ctrlValue.getDate() - 1);
            const filteredListFields = this.allListData.filter(m => {
            return this.dateFormat(m.createdAt) == this.dateFormat(ctrlValue)
          });

        this._listData.next(filteredListFields);
        return of(filteredListFields);
        } else if(value == 'last_week') {
            let newdate = new Date();
            ctrlValue.setDate(ctrlValue.getDate() - 7);
            const filteredListFields = this.allListData.filter((element) => new Date(element.createdAt) > new Date(ctrlValue) && new Date(element.createdAt) <= new Date(newdate));

          this._listData.next(filteredListFields);
        return of(filteredListFields);
        } else if(value == 'last_month') {
            ctrlValue.setDate(ctrlValue.getDate() - 30);
            let newdate = new Date();
            const filteredListFields = this.allListData.filter((element) => new Date(element.createdAt) > new Date(ctrlValue) && new Date(element.createdAt) <= new Date(newdate));
        
          this._listData.next(filteredListFields);
        return of(filteredListFields);
        } else {
            this._listData.next(this.allListData);
        return of(this.allListData);
        }
        
    }

    dateFormat(dateString) {
        const reverse = moment(dateString).format("DD-MM-YYYY");
        return reverse;
    }

    /**
     * Get list fields
     *
     * @param listViewSetup
     * @returns
     */
    getListActions(listViewSetup: any): Observable<any> {
        return this._commonService
            .getDataByField(
                MODELS_CONSTANTS.LIST_ACTIONS,
                'list_view_id',
                listViewSetup._id
            )
            .pipe(
                switchMap(async (response: IResponse<any>) => {
                    this._listActions.next(response?.data || []);
                    return response?.data || [];
                })
            );
    }

    /**
     * Get advanced filters
     */
    getAdvancedFilteres() {
        this._commonService
            .getDataByField(
                MODELS_CONSTANTS.VALUE_SET_DETAILS,
                'vs_code',
                VALUE_SET_CODE_CONSTANTS.ADVANCED_FILTERS
            )
            .subscribe((response) => {
                this._advancedFilters.next(response?.data || []);
            });
    }

    /**
     * Delete List Item
     *
     * @param module_code
     * @param id
     * @returns
     */
    deleteListItem(module_code: string, id: string): Observable<any> {
        return this._commonService.deleteRecordsById(module_code, id).pipe(
            switchMap((response: IResponse<any>) => {
                if (response?.status === 200) {
                    return this.listData$.pipe(
                        take(1),
                        map((listData) => {
                            const index = listData.findIndex((e) => e._id === id);
                            if (index !== -1) {
                                const updatedListData = [...listData];
                                updatedListData.splice(index, 1);
                                this._listData.next(updatedListData);
                            }
                            return response; // Pass along the original response
                        })
                    );
                }
            })
        );
    }
    

    /**
     * Get Dropdown data
     *
     * @param vs_code
     * @returns
     */
    getDropdownData(vs_code: string): Observable<any> {
        return this._commonService.getDataByFields(
            MODELS_CONSTANTS.VALUE_SET_DETAILS,
            {
                vs_code,
                status: 1,
            }
        );
    }
}
