import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { TextFieldModule } from '@angular/cdk/text-field';
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { FuseFindByKeyPipe } from '@fuse/pipes/find-by-key/find-by-key.pipe';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { SharedModule } from 'app/shared/shared.module';
import { assign } from 'lodash-es';
import { DateTime } from 'luxon';
import { Subject, debounceTime, filter, takeUntil, tap } from 'rxjs';
// import { TasksListComponent } from '../list/list.component';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { TasksService } from '../tasks.service';
import { Tag, Task } from '../tasks.types';

@Component({
    selector       : 'tasks-details',
    templateUrl    : './details.component.html',
    styleUrls      : ['./details.component.scss'],
    // encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone     : true,
    imports        : [NgIf, RouterLink, TextFieldModule, NgFor, NgClass, FuseFindByKeyPipe, DatePipe, SharedModule],
})
export class TasksDetailsComponent implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
    @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
    @ViewChild('titleField') private _titleField: ElementRef;

    @Input() data: any;

    tags: Tag[];
    tagsEditMode: boolean = false;
    filteredTags: Tag[];
    task: Task;
    tasks: Task[];
    sections: any;
    contactList: any[] = [];

    private _tagsPanelOverlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    taskForm: FormGroup = this._formBuilder.group({
        // id       : [''],
        type     : [''],
        title    : ['', Validators.required],
        asignTo    : [[]],  
        notes    : [''],
        completed: [false],
        dueDate  : [null],
        priority : [0],
        section_id : [''],
        tags     : [[]],
        order    : [0],
    });
    usersList: any;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _router: Router,
        // private _tasksListComponent: TasksListComponent,
        private _tasksService: TasksService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _snackbar: SnackbarService,
        private _commonService:CommonService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void>
    {
        const contactsResult = await this._commonService?.getAllData(MODELS_CONSTANTS.CONTACTS).toPromise();
        if (contactsResult?.status === 200) {
            this.contactList = contactsResult?.data;
        }

        const userResponse = await this._commonService.getAllData(MODELS_CONSTANTS.USERS).toPromise();
        if (userResponse?.status === 200) {
            this.usersList = userResponse?.data;
        }
        
        // Open the drawer
        this.openDrawer();

        // Get the tags
        this._tasksService.tags$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((tags: Tag[]) =>
            {
                this.tags = tags;
                this.filteredTags = tags || [];

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Get the tasks
        this._tasksService.tasks$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((tasks: Task[]) =>
            {
                this.tasks = tasks || [];
                this.sections = this.tasks.filter((e) => e.type === "section");
                
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Get the task
        this._tasksService.task$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((task: Task) =>
            {
                if(task){
                    // Open the drawer in case it is closed
                    this.openDrawer();
    
                    // Get the task
                    this.task = task;
                    const id = this._activatedRoute.snapshot.paramMap.get('id');
                    if (id !== 'create') {
                        const tagsIds = this.tags?.map((e) => e?._id);
                        task.tags = task?.tags?.filter((e) => tagsIds?.includes(e));
                    }
    
                    // Patch values to the form from the task
                    this.taskForm.patchValue(task, {emitEvent: false});
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        // }

        // Update task when there is a value change on the task form
        // this.taskForm.valueChanges
        //     .pipe(
        //         tap((value) =>
        //         {
        //             // Update the task object
        //             this.task = assign(this.task, value);
        //         }),
        //         debounceTime(300),
        //         takeUntil(this._unsubscribeAll),
        //     )
        //     .subscribe((value) =>
        //     {
        //         // Update the task on the server
        //         this._tasksService.updateTask(value.id, this.task).subscribe();

        //         // Mark for check
        //         this._changeDetectorRef.markForCheck();
        //     });

        // // Update date when there is a value change in date in form
        // this.taskForm.get('dueDate').valueChanges.subscribe((value) => {
        //     this.task.dueDate = value
        // });

        // Listen for NavigationEnd event to focus on the title field
        this._router.events
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter(event => event instanceof NavigationEnd),
            )
            .subscribe(() =>
            {
                // Focus on the title field
                this._titleField.nativeElement.focus();
            });
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void
    {
        // // Listen for matDrawer opened change
        // this.matDrawer?.openedChange
        //     .pipe(
        //         takeUntil(this._unsubscribeAll),
        //         filter(opened => opened),
        //     )
        //     .subscribe(() =>
        //     {
        //         // Focus on the title element
        //         this._titleField.nativeElement.focus();
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._tagsPanelOverlayRef )
        {
            this._tagsPanelOverlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
    * Open Drawer
    */
    openDrawer() {
        this._tasksService.openDrawer()
    }

    /**
     * Close Drawer
     */
    async closeDrawer(): Promise<void> {
        return this._tasksService.closeDrawer();
    }

    /**
     * Toggle the completed status
     */
    toggleCompleted(): void
    {
        // Get the form control for 'completed'
        const completedFormControl = this.taskForm?.get('completed');

        // Toggle the completed status
        completedFormControl.setValue(!completedFormControl.value);
    }

    /**
     * Open tags panel
     */
    openTagsPanel(): void
    {
        // Create the overlay
        this._tagsPanelOverlayRef = this._overlay.create({
            backdropClass   : '',
            hasBackdrop     : true,
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
                .withFlexibleDimensions(true)
                .withViewportMargin(64)
                .withLockedPosition(true)
                .withPositions([
                    {
                        originX : 'start',
                        originY : 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                ]),
        });

        // Subscribe to the attachments observable
        this._tagsPanelOverlayRef.attachments().subscribe(() =>
        {
            // Focus to the search input once the overlay has been attached
            this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
        });

        // Create a portal from the template
        const templatePortal = new TemplatePortal(this._tagsPanel, this._viewContainerRef);

        // Attach the portal to the overlay
        this._tagsPanelOverlayRef.attach(templatePortal);

        // Subscribe to the backdrop click
        this._tagsPanelOverlayRef.backdropClick().subscribe(() =>
        {
            // If overlay exists and attached...
            if ( this._tagsPanelOverlayRef && this._tagsPanelOverlayRef.hasAttached() )
            {
                // Detach it
                this._tagsPanelOverlayRef.detach();

                // Reset the tag filter
                this.filteredTags = this.tags;

                // Toggle the edit mode off
                this.tagsEditMode = false;
            }

            // If template portal exists and attached...
            if ( templatePortal && templatePortal.isAttached )
            {
                // Detach it
                templatePortal.detach();
            }
        });
    }

    /**
     * Toggle the tags edit mode
     */
    toggleTagsEditMode(): void
    {
        this.tagsEditMode = !this.tagsEditMode;
    }

    /**
     * Filter tags
     *
     * @param event
     */
    filterTags(event): void
    {
        // Get the value
        const value = event.target.value.toLowerCase();

        // Filter the tags
        this.filteredTags = this.tags.filter(tag => tag.title.toLowerCase().includes(value));
    }

    /**
     * Filter tags input key down event
     *
     * @param event
     */
    filterTagsInputKeyDown(event): void
    {
        // Return if the pressed key is not 'Enter'
        if ( event.key !== 'Enter' )
        {
            return;
        }

        // If there is no tag available...
        if ( this.filteredTags.length === 0 )
        {
            // Create the tag
            this.createTag(event.target.value);

            // Clear the input
            event.target.value = '';

            // Return
            return;
        }

        // If there is a tag...
        const tag = this.filteredTags[0];
        const isTagApplied = this.task.tags.find(id => id === tag._id);

        // If the found tag is already applied to the task...
        if ( isTagApplied )
        {
            // Remove the tag from the task
            this.deleteTagFromTask(tag);
        }
        else
        {
            // Otherwise add the tag to the task
            this.addTagToTask(tag);
        }
    }

    /**
     * Create a new tag
     *
     * @param title
     */
    createTag(title: string): void
    {
        const tag = {
            title,
        };

        // Create tag on the server
        this._tasksService.createTag(tag)
            .subscribe((response) =>
            {
                // Add the tag to the task
                this.addTagToTask(response);
            });
    }

    /**
     * Update the tag title
     *
     * @param tag
     * @param event
     */
    updateTagTitle(tag: Tag, event): void
    {
        // Update the title on the tag
        tag.title = event.target.value;

        // Update the tag on the server
        this._tasksService.updateTag(tag._id, tag)
            .pipe(debounceTime(300))
            .subscribe();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Delete the tag
     *
     * @param tag
     */
    deleteTag(tag: Tag): void
    {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Remove tag',
            message: 'Are you sure you want to delete this tag? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete',
                },
                cancel: {
                    label: 'Cancel'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
        // Delete the tag from the server
        this._tasksService.deleteTag(tag._id).subscribe();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }
});
}
    

    /**
     * Add tag to the task
     *
     * @param tag
     */
    addTagToTask(tag: Tag): void
    {
        // Add the tag
        if (this.task?.tags && Array.isArray(this.task?.tags)) {
            this.task?.tags?.unshift(tag?._id);
        } else {
            this.task.tags = [tag?._id];
        }

        // Update the task form
        this.taskForm?.get('tags')?.patchValue(this.task?.tags);

        // Mark for check
        this._changeDetectorRef.detectChanges();
    }

    /**
     * Delete tag from the task
     *
     * @param tag
     */
    deleteTagFromTask(tag: Tag): void
    {
        // Remove the tag
        this.task.tags.splice(this.task.tags.findIndex(item => item === tag._id), 1);

        // Update the task form
        this.taskForm?.get('tags')?.patchValue(this.task.tags);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Toggle task tag
     *
     * @param tag
     */
    toggleTaskTag(tag: Tag): void {
        if (this.task?.tags?.includes(tag?._id)) {
            this.deleteTagFromTask(tag);
        } else {
            this.addTagToTask(tag);
        }
    }

    /**
     * Should the create tag button be visible
     *
     * @param inputValue
     */
    shouldShowCreateTagButton(inputValue: string): boolean
    {
        return !!!(inputValue === '' || this.tags.findIndex(tag => tag.title.toLowerCase() === inputValue.toLowerCase()) > -1);
    }

    /**
     * Set the task priority
     *
     * @param priority
     */
    setTaskPriority(priority): void
    {
        // Set the value
        this.taskForm?.get('priority')?.setValue(priority);
        this.task.priority = priority;
        this._changeDetectorRef.detectChanges();
    }

    /**
     * Check if the task is overdue or not
     */
    isOverdue(): boolean
    {
        return DateTime.fromISO(this.task.dueDate).startOf('day') < DateTime.now().startOf('day');
    }

    /**
     * Delete the task
     */
    deleteTask(task): void
    {
        // Determine the title and message based on the task type
        const title = `Delete ${task?.type}`;
        let message = `Are you sure you want to delete this ${task?.type}?<br>`;
    
        if (task?.type === 'section') {
            message += 'It will also delete all tasks within it and cannot be undone.<br>';
        } else {
            message += 'This action cannot be undone!';
        }
    
        // Open the confirmation dialog with the determined title and message
        const confirmation = this._fuseConfirmationService.open({
            title: title,
            message: message,
            actions: {
                confirm: {
                    label: 'Delete',
                },
            },
        });
    
        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) =>
        {
            // If the confirm button pressed...
            if ( result === 'confirmed' )
            {
                // Get the current task's id
                const id = this.task._id;

                // Get the next/previous task's id
                const currentTaskIndex = this.tasks.findIndex(item => item._id === id);
                const nextTaskIndex = currentTaskIndex + ((currentTaskIndex === (this.tasks.length - 1)) ? -1 : 1);
                const nextTaskId = (this.tasks.length === 1 && this.tasks[0]._id === id) ? null : this.tasks[nextTaskIndex]._id;

                // Delete the task
                this._tasksService.deleteTask(id)
                    .subscribe(async (isDeleted) =>
                    {
                        // Return if the task wasn't deleted...
                        if ( !isDeleted )
                        {
                            return;
                        }

                        // Navigate to the next task if available
                        // if ( nextTaskId )
                        // {
                        //     this._router.navigate(['../', nextTaskId], {relativeTo: this._activatedRoute});
                        // }
                        // // Otherwise, navigate to the parent
                        // else
                        // {
                        //     this._router.navigate(['../'], {relativeTo: this._activatedRoute});
                        // }
                        if ( isDeleted )
                            {
                                if(task?.type === 'section'){
                                    for(let ele of task?.tasks){
                                        await this._tasksService.deleteTask(ele?._id).subscribe();
                                    }
                                }
                                this.backToTasks();
                            }
                            else
                            {
                                return;
                            }
                    });

                // Mark for check
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item?._id || index;
    }

    /**
     * Save Taks/Section
     */
    saveTask(task){
        if(this.taskForm.valid) {
            if(task?.type === 'task' && !this.taskForm.value.section_id) {
                this._snackbar.error('Please enter the title and section');
            } else {
                let taskFormData = this.taskForm?.getRawValue();
                task = {...task, ...taskFormData};
                if (!task?._id) {
                task.order = this.tasks?.length
                        ? this.tasks?.[this.tasks?.length - 1]?.order + 1
                    : 1;
                }
                let taskData: any = localStorage.getItem('task_data');
                if(taskData){
                    taskData = JSON.parse(taskData);
                    task = {...task, ...taskData}
                }
                this._tasksService.saveTask(task).subscribe({
                    next: (response: IResponse<any>) => {
                        if (response?.status === 200) {
                            const type = task?.type === 'task' ? 'Task' : 'Section';
                            const message = task?._id ? 'updated' : 'created';
                            this._snackbar.success(`${type} ${message} successfully`);
                            this._tasksService?.getTasks(this.data).subscribe();
                            this.backToTasks();
                        } else {
                            this._snackbar.error(
                                response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                            );
                        }
                    },
                    error: (err) => {
                        console.error(err);
                        this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                    },
                });
            }
        } else {
            this._snackbar.error(task?.type === 'task' ? 'Please enter the title and section' : 'Please enter the title');
        }
      }

    backToTasks(){
        if(this.data){
            this.closeDrawer();
        } else {
            this._router.navigate(['../'], {
                relativeTo: this._activatedRoute,
            });
        }
    }
}
