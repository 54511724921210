import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpParams,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { CommonService } from 'app/shared/services/common/common.service';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const commonService = inject(CommonService);
    const snackbar = inject(SnackbarService);
    
    let isThirdParty = req.headers.get('X-third-party');
    req = req.clone({
        headers: req.headers.delete('X-third-party'),
    });

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (
        authService.access_token &&
        !AuthUtils.isTokenExpired(authService.access_token)
    ) {
        if (isThirdParty !== 'true') {
            newReq = req.clone({
                headers: req.headers.set(
                    'Authorization',
                    'Bearer ' + authService.access_token
                ),
            });
        }
    }

    newReq = commonService.encryptReq(newReq);

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            if (error instanceof HttpErrorResponse && isThirdParty) {
                return throwError({ error: error.error, status: error.status });
            }
            // Catch "401 Unauthorized" responses
            else if (
                error instanceof HttpErrorResponse &&
                error.status === 401
            ) {
                // Show IP error message
                if(error.error.message === 'IP not allowed') {
                    snackbar.error('This IP is not allowed');

                    setTimeout(() => {
                        // Sign out
                        authService.signout();
        
                        // Reload the app
                        location.reload();
                    }, 3000);
                    
                } else {
                    // Sign out
                    authService.signout();
        
                    // Reload the app
                    location.reload();
                }


            }

            return throwError(error.error);
        }),
        tap(async (response) => {
            if (response instanceof HttpResponse) {
                response = commonService.decryptRes(response, newReq);
            }
        })
    );
};
