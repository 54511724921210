export const MODELS_CONSTANTS = {
    MESSAGES: 'messages',
    NOTIFICATIONS: 'notifications',
    SHORTCUTS: 'shortcuts',
    USER_SETTINGS: 'user_settings',
    CHATS: 'chats',
    CONTACTS: 'contacts',
    CHAT_MESSAGES: 'chat_messages',
    CHAT_SETTINGS: 'chat_settings',
    API_REGISTRIES: 'api_registries',
    USERS: 'users',
    MENUS: 'menus',
    MENU_ROLES: 'menu_roles',
    ROLES: 'roles',
    LIST_VIEW: 'list_views',
    LIST_FIELDS: 'list_fields',
    VALUE_SETS: 'value_sets',
    VALUE_SET_DETAILS: 'value_set_details',
    DASHBOARDS: 'dashboards',
    DASHBOARD_DATASOURCES: 'dashboard_datasources',
    DASHBOARD_WIDGETS: 'dashboard_widgets',
    NOTES: 'notes',
    VENDORS: 'VENDORS',
    VENDOR_STAFF: 'VENDOR_STAFF',
    VENDOR_LOCATIONS: 'VENDOR_LOCATIONS',
    VENDOR_BANKS: 'VENDOR_BANKS',
    LABELS: 'labels',
    TASKS: 'tasks',
    FORM_SETP: 'forms_setup',
    FORM_SETP_VERSION: 'forms_versions',
    MODULES: 'modules',
    SCRUMBOARDS: 'scrumboards',
    TENANTS: 'tenants',
    USER_INFO: 'user_infos',
    EVENTS: 'events',
    LABEL_MASTER: 'label_master',
    PROJECT: 'project',
    PROJECT_TASKS:'project_tasks',
    MILESTONE:'milestone',
    FILE_STORAGE: 'file_storage',
    PAYMENT_TERMS:'PAYMENT_TERMS',
    CURRENCY:'CURRENCY',
    LIST_ACTIONS: 'list_actions',
    CONTENT_MANAGEMENT: 'content_management',
    ATTENDANCE:'attendance',
    ANNOUNCEMENT:'announcement',
    FORM_ACTIONS: 'form_actions',
    BOARD_STAGES: 'board_stage',
    FORM_CONVERSATIONS: 'form_conversations',
    FORM_FTR: 'form_footer',
    BOARD_TASK: 'board_tasks',
    HOLIDAYS: 'holidays',
    LEAVE: 'leave',
    COMPONENT_ACTIONS: 'component_actions',
    BOARD_TASK_FORM: 'board_task_form',
    BOARD_TASK_APPROVAL: 'board_task_approval',
    APP_CONFIGS: 'app_configs',
    AUTH_API_CONFIG: 'auth_api_configs',
    TASK_ACTIVITY_TRACKER : 'task_activity_tracker',
    STAGE_ACTIONS: 'stage_actions',
    MORNING_PLANNER: 'morning_planner',
    EVENING_TRACKER: 'evening_tracker',
    FORM_ACTIVITY_TRACKER: 'form_activity_tracker',
    CHRONO_PLANNER: 'chrono_planner',
    FORMS_PROMPT: 'forms_prompt',
    VENDORS_IND: 'vendors_ind',


    // AI Models
    AI_EXCEL_UPLOAD: 'AI_EXCEL_UPLOAD',
    AI_VENDOR_INVOICE: 'AI_VENDOR_INVOICE',
};
