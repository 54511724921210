<div *ngIf="timerAccess">
    <div class="flex flex-row items-center justify-center bg-primary p-2 h-10 min-w-26 rounded-md cursor-pointer"
        (click)="activity_tracker()" [matTooltip]="clockInData?.type === 'clockIn' ? disTooltip : tooltip"
        [ngClass]="{'bg-red-500': clockInData?.type == 'clockIn', 'bg-primary': clockInData?.type != 'clockIn'}">
        <mat-icon [svgIcon]="'heroicons_outline:clock'" class="text-white font-semibold mr-1"
            style="font-size: 16px;"></mat-icon>
        <div class="block">
            <div *ngIf="clockInData?.type !== 'clockIn'" class="text-sm text-white font-semibold">Check-in</div>
            <div *ngIf="clockInData?.type == 'clockIn'" class="text-sm text-white font-semibold">Check-out</div>
            <div class="text-sm text-white font-normal">{{elapsedTime}}</div>
        </div>
    </div>
</div>

<fuse-drawer id="myCustomDrawer" fixed [style.min-width]="drawerWidth" [mode]="'over'" [name]="'fuseDrawer'"
    [position]="'right'" #fuseDrawer>
    <div class="dynamic-tabs-container" id="scrollDrawer"
        [ngClass]="{'h-5/6': !isFormDrawerOpened && submitFlag, 'h-6/6': isFormDrawerOpened}" *ngIf="dynamicTabsData">
        <app-dynamic-tabs [data]="dynamicTabsData"></app-dynamic-tabs>
    </div>
    <ng-container *ngIf="!isFormDrawerOpened">
        <div *ngIf="submitFlag" class="w-full flex justify-end items-center gap-4 py-6 px-6 mt-4">
            <button mat-button class="dashboard-primary-btn cancel-btn border border-gray-300 text-gray-700 
                   hover:bg-gray-100 transition-all duration-300 text-sm px-4 py-1 w-20" type="button"
                (click)="close_dialog(); $event.preventDefault()">
                Cancel
            </button>
            <button mat-flat-button class="btn-primary text-sm px-3 py-0.5 h-8"
                matTooltip="Please update your task status and Save before checking out." (click)="clockOut()">
                Check-out
            </button>
        </div>
    </ng-container>
</fuse-drawer>