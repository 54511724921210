import { Type } from '@angular/core';
import { DynamicFormsBuilderComponent } from 'app/modules/common/forms/forms-builder/forms-builder.component';
import { SettingsAccountComponent } from 'app/modules/core/settings/account/account.component';
import { SettingsNotificationsComponent } from 'app/modules/core/settings/notifications/notifications.component';
import { SettingsSecurityComponent } from 'app/modules/core/settings/security/security.component';
import { TasksComponent } from 'app/modules/core/project-view/tasks/tasks.component';
import { TasksComponent as ToDosComponent } from 'app/modules/features/tasks/tasks.component';
import { MilestoneComponent } from 'app/modules/core/project-view/milestone/milestone.component';
import { ProjectTimesheetComponent } from 'app/modules/core/project-view/time-sheet/time-sheet.component';
import { TenantsAccountComponent } from 'app/modules/admin/tenants/account/account.component';
import { TenantsSecurityComponent } from 'app/modules/admin/tenants/security/security.component';
import { TenantsLocationComponent } from 'app/modules/admin/tenants/location/location.component';
import { TenantsTeamComponent } from 'app/modules/admin/tenants/team/team.component';
import { EventsComponent } from 'app/modules/core/events/events.component';
import { FormHeaderActionsComponent } from 'app/modules/common/forms/forms-builder/form-header-actions/form-header-actions.component';
import { FormsConversationComponent } from 'app/modules/common/forms/forms-conversation/forms-conversation.component';
import { ScrumboardCardDetailsComponent } from 'app/modules/features/scrumboard/card/details/details.component';
import { NotesComponent } from 'app/modules/features/notes/notes.component';
import { AppConfigComponent } from 'app/modules/config/app-config/app-config.component';
import { WorkflowDesignerComponent } from 'app/modules/config/workflow-designer/workflow-designer.component';
import { DynamicTabsComponent } from 'app/modules/common/tabs/dynamic-tabs.component';
import { ContactsListComponent } from 'app/modules/features/contacts/list/list.component';
import { AnnouncementsComponent } from 'app/modules/core/announcements/announcements.component';
import { NotesListComponent } from 'app/modules/features/notes/list/list.component';
import { TasksListComponent } from 'app/modules/features/tasks/list/list.component';
import { ContentManagementComponent } from 'app/modules/core/content-management/content-management.component';
import { ActivityTrackerComponent } from 'app/modules/common/activity-tracker/activity-tracker.component';

export const COMPONENT_REFERENCE: {
    [key: string]: { component: Type<any>, inputs?: any };
} = {
    'settings-account': { component: SettingsAccountComponent },
    'settings-security': { component: SettingsSecurityComponent },
    'settings-notifications': { component: SettingsNotificationsComponent },
    'form_render': { component: null },
    'app-builder': { component: DynamicFormsBuilderComponent },
    'list_view': { component: null },
    'content_management': { component: ContentManagementComponent, inputs: { params: null } },
    //project-view
    'task': { component: TasksComponent, inputs: { row_data: null } },
    'milestone': { component: MilestoneComponent },
    'time-sheet': { component: ProjectTimesheetComponent },
    //tenants
    'account': { component: TenantsAccountComponent },
    'security': { component: TenantsSecurityComponent },
    'location': { component: TenantsLocationComponent },
    'staff': { component: TenantsTeamComponent },
    //features
    'schedule': { component: EventsComponent, inputs: { data: null } },
    'form-header-actions': { component: FormHeaderActionsComponent, inputs: { row_data: null } },
    'form-conversations': { component: FormsConversationComponent, inputs: { data: null } },
    'scrumboard-card-details': { component: ScrumboardCardDetailsComponent, inputs: { row_data: null } },
    'todos': { component: TasksListComponent, inputs: { data: null } },
    'notes': { component: NotesListComponent },
    'app-app-config': { component: AppConfigComponent },
    'workflow': { component: WorkflowDesignerComponent, inputs: { row_data: null } },
    'dynamic-tabs': { component: null },
    'events': { component: EventsComponent, inputs: { data: null } },
    'contacts': { component: ContactsListComponent},
    'announcement': { component: AnnouncementsComponent},
    'activity-tracker': { component: ActivityTrackerComponent, inputs: { form_data: null } }
};

// Load components asynchronously
(async () => {
    const { DynamicFormRendererComponent } = await import('app/modules/common/forms/forms-renderer/forms-renderer.component');
    COMPONENT_REFERENCE['form_render'].component = DynamicFormRendererComponent;

    const { ListComponent } = await import('app/modules/core/list/list.component');
    COMPONENT_REFERENCE['list_view'].component = ListComponent;

    const { ContentManagementComponent } = await import('app/modules/core/content-management/content-management.component');
    COMPONENT_REFERENCE['content_management'].component = ContentManagementComponent;

    const { DynamicTabsComponent } = await import('app/modules/common/tabs/dynamic-tabs.component');
    COMPONENT_REFERENCE['dynamic-tabs'].component = DynamicTabsComponent;

})();


