import { CommonModule, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { AuthService } from 'app/core/auth/auth.service';
import { DynamicTabsComponent } from 'app/modules/common/tabs/dynamic-tabs.component';
import { COMPONENT_REFERENCE } from 'app/shared/constants/component-reference.constants';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ListService } from 'app/modules/core/list/list.service';


@Component({
    selector: 'clock-in-out',
    templateUrl: './clock-in-out.component.html',
    styleUrls: ['./clock-in-out.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatTooltipModule,
        CommonModule,
        ReactiveFormsModule,
        NgIf,
        NgClass,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatSelectModule,
        MatOptionModule,
        MatDrawer,
        MatSidenavModule,
        DynamicTabsComponent,
        FuseDrawerComponent
    ]
})

export class ClockInOutComponent implements OnInit, OnDestroy {
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    @ViewChild('fuseDrawer', { static: true }) fuseDrawer: FuseDrawerComponent;
    isDrawerOpen = false;
    @ViewChild('morningPlanner') morningPlanner: TemplateRef<any>;
    @ViewChild('eveningTracker') eveningTracker: TemplateRef<any>;
    @Output() drawerStateChange = new EventEmitter<boolean>();
    @ViewChild('fuseDrawer', { read: ElementRef }) fuseDrawerElement: ElementRef;
    addWidgetActionsTemplate: TemplateRef<any>;
    clockIn_dialogRef: MatDialogRef<any>;
    clockOut_dialogRef: MatDialogRef<any>;
    @Input() row_data: any;
    @Input() parent_row_data: any;
    dynamicTabsData: any;
    drawerWidth: string;
    receivedData: string;
    dynamicTabsComponent: { component: any };
    clockoutForm: FormGroup;
    clockInForm: FormGroup;
    dialogRef: any;
    user: any;
    attendanceId: string | null = null;
    clockInData: any;
    interval: any;
    elapsedTime: string = '00:00:00';
    tooltip;
    myValue: string = '';
    isDisabled = false;
    disTooltip = '';
    timerAccess = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    projectListByTenant: any;
    projectTasksListByUser: any;
    submitFlag: boolean = false;
    preventClose = false;
    screenWidth = window.innerWidth;
    task_list_view_id = "6721d0e768fa959ae34ca3f0";
    isFuseDrawerOpen: boolean = false;
    permissions = {read: true, write: true};
    isFormDrawerOpened: boolean = false;

    constructor(
        private _commonService: CommonService,
        private _cdr: ChangeDetectorRef,
        private _snackbarService: SnackbarService,
        public dialog: MatDialog,
        private _authService: AuthService,
        private _router: Router,
        private _changeDetectorRef: ChangeDetectorRef,
        private _listService: ListService
    ) { }

    async ngOnInit(): Promise<void> {
        this.user = this._authService.userInfo;
        const response = await this._commonService.getDataById(MODELS_CONSTANTS.TENANTS, this.user?.tenant_id).toPromise();
        if (response?.data?.screen_timer) {
            this.timerAccess = true;
            this.loadData();
        }
        else {
            this.timerAccess = false;
        }
    }

    async loadData() {
        try {
            const response = await this._commonService.getDataByField(MODELS_CONSTANTS.ATTENDANCE, 'user_id', this.user?._id).toPromise();
            this.clockInData = response?.data?.pop();
            this.disTooltip = '';
            if (this.clockInData?.type === 'clockIn') {
                this.attendanceId = this.clockInData?._id;
                localStorage.setItem('time', this.clockInData?.clock_in);
                this.tooltip = 'Clock In at ' + moment(this.clockInData?.clock_in).format('DD/MM/YYYY, h:mm:ss a');
            }
            else {
                this.clearTimer();
                this.tooltip = "You are currently clocked out";
                if (this.clockInData?.clock_out) {
                    const clockOutTime = moment(this.clockInData?.clock_out).format('MM/DD/YYYY');
                    const currentTime = moment();
                    const diff = currentTime.diff(clockOutTime, 'days');
                    if (diff > 0)
                        this.isDisabled = false;
                    else {
                        this.disTooltip = "Once You're Clocked-out you can't Clock-in again on the same day'";
                        this.isDisabled = true;
                    }
                }
            }
            this.dynamicTabsComponent = COMPONENT_REFERENCE['dynamic-tabs'];
            this._cdr.markForCheck();
            this.startTimer();
        } catch (error) {
            console.error('Error loading data', error);
        }
    }

    close_dialog = () => {
        this.isDrawerOpen = false;
        this.fuseDrawer.close();
        this.drawerStateChange.emit(this.isDrawerOpen);
    }

    handle_clock = () => {
        this.handleClock();
    }

    clockOut() {
        this._snackbarService.success(MESSAGE_CONSTANTS.CLOCKED_OUT);
        this.close_dialog();
        setTimeout(() => {
            this.handleClock();
        }, 500);
    }

    // activity_tracker() {
    //     console.log(this.row_data, 'this.row_data');

    //     if (this.clockInData?.type === 'clockIn') {
    //         this.dynamicTabsData = {
    //             form: {
    //                 form_id: '668e2c35460dce7ed0b936a6',
    //                 id: null,
    //                 name: 'Evening Tracker',
    //                 title: 'Evening Tracker',
    //                 componentRef: 'form_render',
    //                 close_dialog: this.close_dialog,
    //                 handle_clock: this.handle_clock,
    //             },
    //             close_dialog: this.close_dialog
    //         };

    //         this.dynamicTabsData = { ...this.dynamicTabsData }
    //         this.fuseDrawer.open();
    //     } else {
    //         this.dynamicTabsData = {
    //             form: {
    //                 form_id: '668686f2dca58946eae167f5',
    //                 id: null,
    //                 name: 'Morning Planner',
    //                 title: 'Morning Planner',
    //                 componentRef: 'form_render',
    //                 close_dialog: this.close_dialog,
    //                 handle_clock: this.handle_clock,
    //             },
    //             close_dialog: this.close_dialog
    //         };

    //         this.dynamicTabsData = { ...this.dynamicTabsData }
    //         this.fuseDrawer.open();
    //     }
    //     // this.handleClock();
    // }

    activity_tracker() {
        if (this.clockInData?.type === 'clockIn') {
            this.drawerWidth = '60rem'
            this.submitFlag = true;
            this.dynamicTabsData = {
                listActions: [
                    {
                        list_view_id: this.task_list_view_id,
                        action_type: 'list_view',
                        list_view: this.task_list_view_id,
                        close_dialog: this.close_dialog,
                        handle_clock: this.handle_clock,
                        extra: {
                            isFormOpened: this.isFormOpened,
                            permissions: this.permissions,
                        },
                    },
                ],
                row_data: this.row_data,
                close_dialog: this.close_dialog,
            };
        } else {
            this.drawerWidth = '50rem'
            this.submitFlag = false;
            this.dynamicTabsData = {
                form: {
                    form_id: '668686f2dca58946eae167f5',
                    id: null,
                    name: 'Morning Planner',
                    title: 'Morning Planner',
                    componentRef: 'form_render',
                    close_dialog: this.close_dialog,
                    handle_clock: this.handle_clock,
                },
                close_dialog: this.close_dialog
            };
        }
            this.isDrawerOpen = true;
            this.fuseDrawer.open();
            this.preventClose = true;
            setTimeout(() => {
                this.preventClose = false;
            }, 200); 
    
            this.drawerStateChange.emit(this.isDrawerOpen);
    }

    closeDrawer() {
        this.isDrawerOpen = false;
        this.fuseDrawer.close();
        this.drawerStateChange.emit(this.isDrawerOpen);
    }

    async handleClock() {
        let currentDate: any = new Date();
        let clockJson: any = {
            type: null,
            reason: 'NA',
            hours: '-'
        };
        if (this.clockInData?.type === 'clockIn') {
            const clockInTime = moment(localStorage.getItem('time'));
            const clockOutTime = moment();
            const duration = moment.duration(clockOutTime.diff(clockInTime));
            const totalHours = duration.asHours();
            clockJson.type = "clockOut",
                clockJson.clock_out = currentDate
            clockJson.hours = await this.formatString(totalHours?.toString()) + ' Hrs';
            await this.clockRecords(clockJson);
        } else {
            localStorage.setItem('time', currentDate.toString());
            clockJson.type = "clockIn",
                clockJson.clock_in = currentDate
            await this.clockRecords(clockJson);
        }
    }

    clockRecords(clockJson) {
        clockJson.user_id = this.user?._id;
        clockJson.name = this.user?.name;
        if (clockJson.type == 'clockOut')
            clockJson._id = this.attendanceId;
        this._commonService.saveRecord(MODELS_CONSTANTS.ATTENDANCE, clockJson).subscribe((response) => {
            let resData = response?.data;
            console.log('resData', resData);
            if (resData?._id) {
                this.clearTimer();
                this.loadData();
            } else {
                this._snackbarService.error(response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            }
        });
    }

    startTimer() {
        if (this.interval) {
            this.clearTimer();
        }
        let startTime: any = localStorage.getItem('time');
        if (!startTime && this.clockInData?.type == 'clockIn') {
            startTime = this.clockInData?.clock_in;
            localStorage.setItem('time', startTime);
        }
        if (!startTime) return;
        startTime = new Date(startTime).getTime();
        this.interval = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const duration = moment.duration(elapsedTime);
            const totalDays = duration.days() + (duration.years() * 365) + (duration.months() * 30);
            const hours = duration.hours() + totalDays * 24;
            const formattedTime = `${hours.toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`;
            this.elapsedTime = formattedTime;
            this._cdr.markForCheck();
        }, 1000);
    }

    clearTimer() {
        localStorage.setItem('time', '');
        this.elapsedTime = '00:00:00';
        clearInterval(this.interval);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    formatString(input: string): string {
        const num = parseFloat(input);
        return Math.round(num)?.toString();
    }

    onDrawerStateChange(isOpen: boolean): void {
        this.isDrawerOpen = isOpen;
        this.drawerStateChange.emit(this.isDrawerOpen);
    }

    getResponsiveWidth(screenWidth: number, type: string): string {
        if (screenWidth <= 960) {
            return '100%';
        } else {
            return type === 'clockIn' ? '60rem' : '50rem';
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        const screenWidth = event.target.innerWidth;
        this.drawerWidth = this.getResponsiveWidth(screenWidth, this.clockInData?.type === 'clockIn' ? 'clockIn' : 'clockOut');
    }
    @HostListener('document:click', ['$event'])
    handleClickOutside(event: MouseEvent) {
        const clickedInside = this.fuseDrawerElement.nativeElement.contains(event.target);
        const clickedOnSpecificClass = (event.target as HTMLElement).closest('.fuse-drawer-overlay');
        if (clickedInside) {
            return;
        }
        if (clickedOnSpecificClass) {
            this.closeDrawer(); 
        }
    }

    isFormOpened = (isOpen) => {
        this.isFormDrawerOpened = isOpen;
    }
}
