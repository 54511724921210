<div class=" flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
  <div class="relative flex flex-col colmobileres sm:flex-row flex-0 sm:items-center sm:justify-between scrollmobileres px-4"
    style="align-items:center!important">
    <div class="header">
      Events

    </div>
    <div class="eventmobileres lable-response ">
      <div class="relative flex  flex-row  rowmobileres eventresponse  flex-0 sm:items-center sm:justify-between">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript h-14 sm-Matfield fuse-mat-rounded w-40  mr-2 ">
          <mat-select class="pb-1"placeholder="Event Label" (selectionChange)="labelFilter($event)">
            <mat-option>-Event label-</mat-option>
            <mat-option *ngFor="let label of labelList;let i=index" [value]="label">
              {{label.labelName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript h-14 sm-Matfield fuse-mat-rounded w-40  mr-2  events-label">
          <mat-select class="pb-1"placeholder="Event Type" (selectionChange)="eventTypeFilter($event)">
            <mat-option>-Event type-</mat-option>
            <mat-option *ngFor="let event of eventType;let i=index" [value]="event">
              {{event.vsd_value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-mini-fab [matTooltip]="'Add'" color="primary" class=" shadow-md ml-2 rotate-on-hover buttonresponse "
        (click)="openNew()" >
        <mat-icon>add</mat-icon>
    </button>
    <button mat-mini-fab [matTooltip]="'Manage Labels'" color="primary" class="  shadow-md ml-2 rotate-on-hover buttonresponse "
    (click)="addLabelData()"
    (click)="openDialogWithTemplateRef(myDialog)">
        <mat-icon [svgIcon]="'heroicons_outline:tag'"></mat-icon>
    </button>
      </div>
      <div class="relative flex flex-col   paddingmobileres  lg:flex-row  menu-response">
        <div class="hide-text ">
        <button class="ml-0 border manageButton fuse-mat-dense h-14 sm-Matfield fuse-mat-rounded mr-2 " mat-flat-button (click)="addLabelData()"
          (click)="openDialogWithTemplateRef(myDialog)">
          <mat-icon [svgIcon]="'heroicons_outline:tag'"></mat-icon>
          <span class="ml-2 mr-1">Manage Labels</span>
        </button>
      </div>
      <div class="hide-text">
        <button mat-mini-fab [matTooltip]="'Add Event'" color="primary" class="ml-0 menu-response addwidthres shadow-md rotate-on-hover "
        (click)="openNew()" >
        <mat-icon>add</mat-icon>
    </button>
      </div>
      </div>
    </div>

  </div>

  <div class="paddingscrollres">
    <full-calendar #calendar defaultView="dayGridMonth" [options]="calendarOptions" deepChangeDetection="true"
      (dateClick)="openDialogWithTemplateRef(myDialog)" class="cursor-pointer"></full-calendar>
  </div>
</div>


<ng-template #eventDetailsDialog>
  <div style="width: 100%;">
    <div class="flex border-b p-2 py-0">
      <div matDialogTitle class="text-3xl font-normal tracking-tight">Event details</div>
      <mat-icon [matTooltip]="'Close'" mat-dialog-close class="ml-auto">close</mat-icon>
    </div>

    <div class="card-body border-b">
      <div class="row mb15">
        <div class="col-md-12 p-2 capital text-3xl font-normal tracking-tight">{{eventTitle}}</div>
        <div class="col-md-12 p-2 flex">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>&nbsp;
          <div class="capital">{{startDate | date: 'EE, MMMM d'}}, {{startTime | date:'h:mm:ss a'}} - {{endDate |
            date: 'EE, MMMM d'}}, {{endTime | date:'h:mm:ss a'}}</div>
        </div>
        <div class="col-md-12 m-2 w-full h-10 py-2 px-4 desc-color" [ngStyle]="{'border-color': eventColor}">
          {{eventDescription}}
        </div>
        <div class="col-md-12 p-2 flex">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
          </svg>&nbsp;
          <div class="capital">{{location}}</div>
        </div>
      </div>
    </div>

    <div class="example-button-row mt-3 mb-2 px-2" align="end">
      <button class="ml-0 " mat-flat-button [color]="'primary'" (click)="deleteEvent()">
        <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
        <span class="ml-2 mr-1">Delete</span>
      </button>
      <button class="ml-2 " mat-flat-button [color]="'primary'" (click)="toggleEventFun()">
        <mat-icon [svgIcon]="'heroicons_outline:pencil-square'"></mat-icon>
        <span class="ml-2 mr-1">Edit</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #eventDialog>
<ng-dialog-actions class="overflow-hidden">

  <div class="relative flex flex-row sm:flex-row flex-0 sm:items-center sm:justify-between border-b p-2">

    <div class="addheader">Events</div>
    
    <div class="flex flex-row ml-auto">
      <button class="ml-0 menu-response" mat-flat-button [color]="'primary'" (click)="save()">
        <!-- <mat-icon [svgIcon]="'heroicons_outline:check'"></mat-icon> -->
        <span class="ml-2 mr-1">Save</span>
      </button>
    <mat-icon [matTooltip]="'Close'" mat-dialog-close class="mt-1	ml-4">close</mat-icon>
    </div>
  </div>

  <div class="flex-auto -mt-12 rounded-t-2xl shadow overflow-y-auto pt-10 pl-4">
    <form [formGroup]="eventForm" class="px-6" class="overflow-y-auto">
      <div class="form-scroll overflow-x-hidden">
        <div class="grid sm:grid-cols-4 gap-5 w-full mt-8">
          <div class="sm:col-span-2">
            <mat-form-field appearance="outline"  class="w-full">
                <mat-label>Event Title</mat-label>
                <input matInput formControlName="eventTitle" >
            </mat-form-field>
        </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full"  appearance="outline">
              <mat-label>Event Description</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:calendar'" matPrefix></mat-icon>
              <input formControlName="eventDescription" matInput>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="startpicker" [placeholder]="'Start Date'"
                      [max]="eventForm.controls['endDate'].value" formControlName="startDate" required autofocus
                      (click)="startpicker.open()" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="startpicker"></mat-datepicker-toggle>
                    <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>
          </div>
    
          <div class="sm:col-span-1 relative" >
            <mat-label class="custom-label">Start Time</mat-label>
            <div class="border h-10 text-center	flex justify-center	border-slate-300 rounded-md">
              <input matInput type="time" formControlName="startTime" class="cursor-pointer custom-input">
            </div>
          </div>
          <div class="sm:col-span-1">
            <mat-form-field class="w-full"  appearance="outline">
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="endpicker" [placeholder]="'End Date'"
                      [min]="eventForm.controls['startDate'].value" formControlName="endDate" required autofocus
                      (click)="endpicker.open()" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="endpicker"></mat-datepicker-toggle>
                    <mat-datepicker #endpicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 relative">
            <mat-label class="custom-label">End Time</mat-label>
            <div class="border h-10 text-center	flex justify-center	border-slate-300 rounded-md">
              <input matInput type="time" formControlName="endTime" class="cursor-pointer custom-input">
            </div>
          </div>
        </div>
        <div class="grid sm:grid-cols-4 gap-5 w-full">
          <div class="sm:col-span-1 mt-5">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Project</mat-label>
              <mat-select placeholder="Select Project" formControlName="project">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let project of projectList;let i=index" [value]="project._id">
                  {{project.projectTitle}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 mt-5">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Label</mat-label>
              <mat-select placeholder="Select Label" formControlName="label">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let label of labelList;let i=index" [value]="label?._id">
                  {{label?.labelName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-2 ">
            <mat-label>Share Width</mat-label>
            <mat-radio-group class="grid fuse-mat-no-subscript w-full mt-1" [color]="'primary'" (change)="onSelectChange($event)">
              <div class="flex flex-row">
                <mat-radio-button class="mr-2" *ngFor="let share of shareWithList; let i=index" [value]="i" [checked]="share.checkedValue">
                  {{share.name}}
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
        </div>
        <div class="grid sm:grid-cols-4 gap-5 w-full">
          <div class="sm:col-span-1 mt-5" *ngIf="specificMembers">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Specific Members</mat-label>
              <mat-select placeholder="Select Member" multiple formControlName="selectedMember">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let member of specificMemberList;let i=index" [value]="member?.user_id">
                  {{member?.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 mt-5">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Location</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:map-pin'" matPrefix></mat-icon>
              <input matInput formControlName="location" [placeholder]="'Location'" [spellcheck]="false">
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 mt-5">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Color</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:pencil'" [ngStyle]="{'color': eventColor}" matPrefix></mat-icon>
              <input matInput type="color" formControlName="eventColour" [(ngModel)]="eventColor">
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 row mt-5">
            <div class="color-palet2">
              <div class="flex flex-row -mt-1 ml-4">
                <mat-label>Repeat</mat-label>
                <section>
                  <mat-checkbox class="-mt-1.5" color="primary" [checked]="repeatFlag"
                    (change)="changeEvent($event)"></mat-checkbox>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="grid sm:grid-cols-7 gap-5 w-full" *ngIf="repeatFlag">
          <div class="sm:col-span-1 mt-5">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Duration</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:clock'" matPrefix></mat-icon>
              <input matInput type="number" placeholder="Duration" [spellcheck]="false"
              formControlName="duration">
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 mt-5">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Frequency</mat-label>
              <mat-select placeholder="Frequency" formControlName="frequency">
                <mat-option *ngFor="let months of monthList;let i=index" [value]="months.name">
                  {{months.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 mt-5">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Cycle</mat-label>
              <input matInput type="number" placeholder="Cycles" [spellcheck]="false" formControlName="cycles">
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 mt-5">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Exception</mat-label>
              <mat-select placeholder="Exception" formControlName="exception" multiple>
                <mat-option *ngFor="let excep of exceptionList;let i=index" [value]="excep.name">
                  {{excep.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-2" *ngIf="eventForm.controls['exception']?.value && eventForm.controls['exception']?.value[0]=='Holiday'">
            <mat-label>Holiday</mat-label>
            <mat-radio-group class="grid fuse-mat-no-subscript w-full mt-1" [color]="'primary'" (change)="skipChange($event)">
              <div class="flex flex-row">
                <mat-radio-button class="mr-2" *ngFor="let skip of skipList; let i=index" [value]="i" [checked]="skip.checkedValue">
                  {{skip.name}}
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div class="sm:col-span-1 mt-5">
            <div class="flex items-center">
              <mat-icon class="icon icon-size-5 text-secondary m-auto ml-2 mt-2"
              matTooltip="Recurring will be stopped after the number of cycles. Keep it blank for infinity."
              [svgIcon]="'heroicons_outline:question-mark-circle'">
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-dialog-actions>

</ng-template>

<!-- Label drawer-->
<ng-template #myDialog>
   
<div class="relative pb-4 flex flex-row flex-0 sm:items-center sm:justify-between px-8 ">
 <div class="headerlabels">Manage Labels</div>
 <div class="flex flex-row">
  <button class="ml-0 menu-response" mat-flat-button [color]="'primary'" (click)="saveLabel()">
    <!-- <mat-icon [svgIcon]="'heroicons_outline:check'"></mat-icon> -->
    <span class="ml-2 mr-1">Save</span>
  </button>
  <mat-icon [matTooltip]="'Close'" mat-dialog-close class="mt-1	ml-4">close</mat-icon>
</div>
</div>
<mat-dialog-content>
 <div class="modal-body" style="padding-bottom:10px;">
   <form [formGroup]="labelForm" style="width: 100%;" (keydown.enter)="saveLabel()">
     <div class="row label-border">
       <div class="col-12" style="display:contents;">
         <div class="col-12">
           <div class="form-group position-relative">
             <div class="flex w-full manage">
              <div class="w-20 flex justify-center align-items-center">
                <mat-form-field appearance="outline" class="w-full">
                  <input matInput type="color" formControlName="labelColour" [(ngModel)]="labelColor">
                </mat-form-field>
        </div>
               <mat-form-field class="col-md-10 fuse-mat-dense fuse-mat-no-subscript w-full">
                 <input matInput formControlName="labelName" [placeholder]="'Label'" [spellcheck]="false">
                 <div *ngIf="editFlag">
                  <div class="bg-card" [matTooltip]="'Delete'"  (click)="deleteLabelData()"  [disabled]="(accessRestriction?.delete_self || accessRestriction?.delete_all)? false:true"> 
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>                    
                </div>
                 </div>
               </mat-form-field>
               <div *ngIf="editFlag" class="flex delete-cancel-btn" >
                 <div class="bg-card " [matTooltip]="'Cancel selection'" 
                 (click)="addLabelData()"> 
                 <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                 </div>
             </div>
               
             </div>
             <div class="flex flex-row mt-4">
              <div class="flex flex-row">
                <div 
                  *ngFor="let label of labelList; let i = index"
                  [ngStyle]="{'background-color': label?.labelColour}" 
                  [matTooltip]="'Pick a label'"
                  (click)="editLabelData(label)"
                  class="label flex items-center m-1 py-0.5 px-3 rounded-full text-sm font-medium cursor-pointer">
                  <span class="text-color">{{ label?.labelName }}</span>
                </div>
              </div>
            </div>
           </div>
         </div>
       </div>
     </div>
   </form>
 </div>
</mat-dialog-content>

</ng-template>