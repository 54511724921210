<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div class="flex flex-auto justify-center w-full sm:p-4 md:p-8 bg-gray-200 dark:bg-card">

    <!-- Navigation -->
    <ng-container *ngIf="isScreenSmall">
        <fuse-vertical-navigation
            class="dark bg-gray-900 print:hidden"
            [mode]="'over'"
            [name]="'mainNavigation'"
            [navigation]="navigation"
            [opened]="false">
            <!-- Navigation header hook -->
            <ng-container fuseVerticalNavigationContentHeader>
  <!-- Logo -->
  <div class="flex items-center h-20 pt-6 px-8" [ngStyle]="{ 'color': overlayColor }" (click)="changeColor()">
    <!-- <img class="w-30" src="assets/images/logo/logo-text-on-dark.svg"> -->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" class="w-30">
        <path fill="var(--fuse-primary-400)" d="M166.13,176.45c-.9,0-1.78,0-2.65.09s-1.73-.09-2.63-.09Z"/>
        <path fill="var(--fuse-primary-400)" d="M647.09,236.12,460.16,559.91,389.4,682.5,318.62,559.91l-4.53-7.84L238.78,421.66,163.48,291.2l-31.8-55.08c-14.85-25.72,2.75-57.73,31.8-59.58.87-.07,1.75-.09,2.65-.09H612.65C643.28,176.45,662.42,209.61,647.09,236.12Z"/>
        <path fill="var(--fuse-primary-400)" d="M948.3,236.12,761.37,559.91,574.44,883.67c-15.31,26.51-53.59,26.51-68.89,0l-40.87-70.76,146.1-253L797.71,236.12c15.3-26.51-3.84-59.67-34.45-59.67h150.6C944.49,176.45,963.63,209.61,948.3,236.12Z"/>
      </svg>
  </div>
</ng-container>
        </fuse-vertical-navigation>
    </ng-container>

    <!-- Wrapper -->
    <div class="flex flex-col items-center flex-auto min-w-0 max-w-360 sm:rounded-xl shadow-2xl dark:shadow-none overflow-hidden">

        <!-- Header -->
        <div class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 bg-card border-b dark:bg-default print:hidden overflow-x-auto">
            <ng-container *ngIf="!isScreenSmall">
                <!-- Logo -->
                <div class="flex items-center mx-2 lg:mr-8">
                    <div class="hidden lg:flex">
                        <!-- Light version -->
                        <!-- <img
                            class="dark:hidden w-24"
                            src="assets/images/logo/logo-text.svg"
                            alt="Logo image"> -->
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" class="dark:hidden w-24">
                                <path fill="var(--fuse-primary-400)" d="M166.13,176.45c-.9,0-1.78,0-2.65.09s-1.73-.09-2.63-.09Z"/>
                                <path fill="var(--fuse-primary-400)" d="M647.09,236.12,460.16,559.91,389.4,682.5,318.62,559.91l-4.53-7.84L238.78,421.66,163.48,291.2l-31.8-55.08c-14.85-25.72,2.75-57.73,31.8-59.58.87-.07,1.75-.09,2.65-.09H612.65C643.28,176.45,662.42,209.61,647.09,236.12Z"/>
                                <path fill="var(--fuse-primary-400)" d="M948.3,236.12,761.37,559.91,574.44,883.67c-15.31,26.51-53.59,26.51-68.89,0l-40.87-70.76,146.1-253L797.71,236.12c15.3-26.51-3.84-59.67-34.45-59.67h150.6C944.49,176.45,963.63,209.61,948.3,236.12Z"/>
                              </svg>
                        <!-- Dark version -->
                        <!-- <img
                            class="hidden dark:flex w-24"
                            src="assets/images/logo/logo-text-on-dark.svg"
                            alt="Logo image"> -->
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" class="hidden dark:flex w-24">
                                <path fill="var(--fuse-primary-400)" d="M166.13,176.45c-.9,0-1.78,0-2.65.09s-1.73-.09-2.63-.09Z"/>
                                <path fill="var(--fuse-primary-400)" d="M647.09,236.12,460.16,559.91,389.4,682.5,318.62,559.91l-4.53-7.84L238.78,421.66,163.48,291.2l-31.8-55.08c-14.85-25.72,2.75-57.73,31.8-59.58.87-.07,1.75-.09,2.65-.09H612.65C643.28,176.45,662.42,209.61,647.09,236.12Z"/>
                                <path fill="var(--fuse-primary-400)" d="M948.3,236.12,761.37,559.91,574.44,883.67c-15.31,26.51-53.59,26.51-68.89,0l-40.87-70.76,146.1-253L797.71,236.12c15.3-26.51-3.84-59.67-34.45-59.67h150.6C944.49,176.45,963.63,209.61,948.3,236.12Z"/>
                              </svg>
                    </div>
                    <!-- Small version -->
                    <!-- <img
                        class="flex lg:hidden w-8"
                        src="assets/images/logo/logo.svg"
                        alt="Logo image"> -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" class="flex lg:hidden w-8">
                            <path fill="var(--fuse-primary-400)" d="M166.13,176.45c-.9,0-1.78,0-2.65.09s-1.73-.09-2.63-.09Z"/>
                            <path fill="var(--fuse-primary-400)" d="M647.09,236.12,460.16,559.91,389.4,682.5,318.62,559.91l-4.53-7.84L238.78,421.66,163.48,291.2l-31.8-55.08c-14.85-25.72,2.75-57.73,31.8-59.58.87-.07,1.75-.09,2.65-.09H612.65C643.28,176.45,662.42,209.61,647.09,236.12Z"/>
                            <path fill="var(--fuse-primary-400)" d="M948.3,236.12,761.37,559.91,574.44,883.67c-15.31,26.51-53.59,26.51-68.89,0l-40.87-70.76,146.1-253L797.71,236.12c15.3-26.51-3.84-59.67-34.45-59.67h150.6C944.49,176.45,963.63,209.61,948.3,236.12Z"/>
                          </svg>
                </div>
                <!-- Horizontal navigation -->
                <fuse-horizontal-navigation
                    class="mr-2"
                    [name]="'mainNavigation'"
                    [navigation]="navigation?.horizontal"></fuse-horizontal-navigation>
            </ng-container>
            <!-- Navigation toggle button -->
            <ng-container *ngIf="isScreenSmall">
                <button
                    class="mr-2"
                    mat-icon-button
                    (click)="toggleNavigation('mainNavigation')">
                    <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                </button>
            </ng-container>
            <!-- Components -->
            <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
                <!--<languages></languages> -->
                <clock-in-out></clock-in-out>
                <fuse-fullscreen class="screen-expand"></fuse-fullscreen>
                <search [appearance]="'bar'"></search>
                <shortcuts></shortcuts>
                <messages></messages>
                <notifications></notifications>
                <user></user>
            </div>
        </div>

        <!-- Content -->
        <div class="flex flex-col flex-auto w-full bg-default lg:h-screen">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>

        <!-- Footer -->
        <div class="relative flex flex-0 items-center justify-start w-full h-16 sm:h-20 px-6 sm:px-8 z-49 bg-card border-t dark:bg-default print:hidden">
        <span class="font-medium text-secondary">{{product_name}} &copy; {{currentYear}}</span>
        </div>

    </div>

</div>
