<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <button
        [matTooltip]="'Search'"
        mat-icon-button
        (click)="open()">
        <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
    </button>
    <div
        class="absolute mobile-search inset-0 flex items-center shrink-0 z-99 bg-card"
        *ngIf="opened"
        @slideInTop
        @slideOutTop>
        <mat-icon
            class="absolute ml-6 sm:ml-8"
            [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
        <input
            class="w-full h-full px-16 sm:px-18"
            [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete"
            [placeholder]="'Search...'"
            (keydown)="onKeydown($event)"
            #barSearchInput>
        <mat-autocomplete
            class="max-h-128 sm:px-2 border-t rounded-b shadow-md"
            [autoSelectActiveOption]="true"
            [disableRipple]="true"
            #matAutocomplete="matAutocomplete">
            <mat-option
                class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <span class="text-md font-semibold tracking-wider text-secondary">Search results </span>
            <ng-container *ngFor="let result of resultSets; trackBy: trackByFn">
                <mat-option (click)="onResultClick(result.link)"
                    class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                    [value]="result.value">
                    <!-- All Result Template -->
                    <ng-container *ngTemplateOutlet="AllResult; context: {$implicit: result}"></ng-container>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
        <button
            class="absolute top-1/2 right-7 shrink-0 w-10 h-10 -mt-5 mr-5"
            mat-icon-button
            (click)="close()">
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-80">
        <mat-form-field
            class="w-full"
            [subscriptSizing]="'dynamic'">
            <mat-icon
                matPrefix
                [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
            <input
                matInput
                [formControl]="searchControl"
                [matAutocomplete]="matAutocomplete"
                [placeholder]="'Search...'"
                (keydown)="onKeydown($event)">
        </mat-form-field>
        <mat-autocomplete
            class="max-h-128 mt-1 rounded"
            [autoSelectActiveOption]="true"
            [disableRipple]="true"
            #matAutocomplete="matAutocomplete">
            <mat-option
                class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-optgroup class="flex items-center mt-2 px-2">
                    <span class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of resultSets; trackBy: trackByFn">
                    <mat-option
                        class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover border-b"
                        [routerLink]="resultSet.link"
                        [value]="resultSet.value">
                        <!-- Contacts -->
                        <ng-container >
                            <ng-container *ngTemplateOutlet="AllResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <!-- Contacts -->
                        <ng-container *ngIf="resultSet.id === 'contacts'">
                            <ng-container *ngTemplateOutlet="contactResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <!-- Pages -->
                        <ng-container *ngIf="resultSet.id === 'pages'">
                            <ng-container *ngTemplateOutlet="pageResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <!-- Tasks -->
                        <ng-container *ngIf="resultSet.id === 'tasks'">
                            <ng-container *ngTemplateOutlet="taskResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
    </div>
</ng-container>

<!-- all result template -->
<ng-template
    #AllResult
    let-result>
   <div class=" w-full"  [ngClass]="{' my-4 ': !result.parent_menu}">
    <label *ngIf="result.parent_menu"  class="text-sm  border my-2 py-2 px-3 bg-primary rounded-md text-white font-semibold text-md" style="top: 25px;position: relative;right: -10px;">{{result.parent_menu}}</label>
    <mat-card style="z-index: 1;position: relative;" [routerLink]="result.link" class="flex gap-3 justify-between my-3 shadow-md  w-80 hover:scale-105 ease-in-out duration-300 bg-primary-100 filter-info border rounded-lg">
        <div class="w-10 h-10 m-auto ml-4 rounded-full bg-primary-700 text-white flex items-center justify-center">
            {{ result.label.charAt(0).toUpperCase() }} 
        </div>         
        <div class="flex flex-col flex-auto justify-center  self-center">
            <div class="text-lg font-semibold leading-tight">{{ result.label }}</div>
        </div>
            <div class="w-16 h-full py-4 bg-primary-800 flex items-center justify-center rounded-r-lg">
                <mat-icon
                    class="m-0 icon-size-6 text-white dark:text-primary-400"
                    *ngIf="!result.avatar"
                    [svgIcon]="result.menu_icon">
                </mat-icon>
            </div> 
    </mat-card>
    </div>
</ng-template>

<!-- Contact result template -->
<ng-template
    #contactResult
    let-result>
    <div class="flex items-center">
        <div class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800"  >
            <img
                *ngIf="result.avatar"
                [src]="result.avatar">
            <mat-icon
                class="m-0 icon-size-5 text-primary dark:text-primary-400"
                *ngIf="!result.avatar"
                [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        </div>
        <div class="ml-3 truncate">
            <span [innerHTML]="result.name"></span>
        </div>
    </div>
</ng-template>


<!-- Page result template -->
<ng-template
    #pageResult
    let-result>
    <div class="flex flex-col">
        <div
            class="truncate leading-normal"
            [innerHTML]="result.title"></div>
        <div class="truncate leading-normal text-sm text-secondary">
            {{result.link}}
        </div>
    </div>
</ng-template>

<!-- Task result template -->
<ng-template
    #taskResult
    let-result>
    <div class="flex items-center">
        <ng-container *ngIf="result.completed">
            <mat-icon
                class="mr-0 text-primary dark:text-primary-400"
                [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
        </ng-container>
        <ng-container *ngIf="!result.completed">
            <mat-icon
                class="mr-0 text-hint"
                [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
        </ng-container>
        <div
            class="ml-3 truncate leading-normal"
            [ngClass]="{'line-through text-hint': result.completed}"
            [innerHTML]="result.title"></div>
    </div>
</ng-template>
