<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <!-- <img
                    class="w-24"
                    src="assets/images/logo/logo-text-on-dark.svg"
                    alt="Logo image"> -->
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" class="w-24">
                        <path fill="var(--fuse-primary-400)" d="M166.13,176.45c-.9,0-1.78,0-2.65.09s-1.73-.09-2.63-.09Z"/>
                        <path fill="var(--fuse-primary-400)" d="M647.09,236.12,460.16,559.91,389.4,682.5,318.62,559.91l-4.53-7.84L238.78,421.66,163.48,291.2l-31.8-55.08c-14.85-25.72,2.75-57.73,31.8-59.58.87-.07,1.75-.09,2.65-.09H612.65C643.28,176.45,662.42,209.61,647.09,236.12Z"/>
                        <path fill="var(--fuse-primary-400)" d="M948.3,236.12,761.37,559.91,574.44,883.67c-15.31,26.51-53.59,26.51-68.89,0l-40.87-70.76,146.1-253L797.71,236.12c15.3-26.51-3.84-59.67-34.45-59.67h150.6C944.49,176.45,963.63,209.61,948.3,236.12Z"/>
                      </svg>
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <!-- Header -->
    <div class="relative flex flex-col flex-0 justify-center w-full h-16 sm:h-20 md:h-36 overflow-hidden z-49 shadow dark:shadow-none print:hidden">
        <!-- Top bar -->
        <div class="relative dark flex flex-auto justify-center w-full px-4 md:px-8 bg-gray-800 dark:bg-gray-900 overflow-x-auto">
            <div class="flex items-center w-full max-w-360 h-16 sm:h-20">
                <!-- Logo -->
                <ng-container *ngIf="!isScreenSmall">
                    <div class="flex items-center">
                        <!-- <img
                            class="w-10"
                            src="assets/images/logo/logo-text-on-dark.svg"
                            alt="Logo image"> -->
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" class="w-10">
                                <path fill="var(--fuse-primary-400)" d="M166.13,176.45c-.9,0-1.78,0-2.65.09s-1.73-.09-2.63-.09Z"/>
                                <path fill="var(--fuse-primary-400)" d="M647.09,236.12,460.16,559.91,389.4,682.5,318.62,559.91l-4.53-7.84L238.78,421.66,163.48,291.2l-31.8-55.08c-14.85-25.72,2.75-57.73,31.8-59.58.87-.07,1.75-.09,2.65-.09H612.65C643.28,176.45,662.42,209.61,647.09,236.12Z"/>
                                <path fill="var(--fuse-primary-400)" d="M948.3,236.12,761.37,559.91,574.44,883.67c-15.31,26.51-53.59,26.51-68.89,0l-40.87-70.76,146.1-253L797.71,236.12c15.3-26.51-3.84-59.67-34.45-59.67h150.6C944.49,176.45,963.63,209.61,948.3,236.12Z"/>
                              </svg>
                    </div>
                </ng-container>
                <!-- Navigation toggle button -->
                <ng-container *ngIf="isScreenSmall">
                    <button
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                    </button>
                </ng-container>
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto mr-7 space-x-0.5 sm:space-x-2">
                    <!--<languages></languages> -->
                    <clock-in-out></clock-in-out>
                    <fuse-fullscreen class="screen-expand"></fuse-fullscreen>
                    <search [appearance]="'bar'"></search>
                    <shortcuts></shortcuts>
                    <messages></messages>
                    <notifications></notifications>
                    <button
                        class="lg:hidden"
                        mat-icon-button
                        (click)="quickChat.toggle()">
                        <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
                    </button>
                    <user></user>
                </div>
            </div>
        </div>
        <!-- Bottom bar -->
        <ng-container *ngIf="!isScreenSmall">
            <div class="flex flex-auto justify-center px-4 md:px-8 bg-card dark:bg-gray-700">
                <div class="relative flex items-center w-full max-w-360 overflow-x-auto">
                    <fuse-horizontal-navigation
                        class="-mx-4"
                        [name]="'mainNavigation'"
                        [navigation]="navigation?.horizontal"></fuse-horizontal-navigation>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:p-6 md:p-8">
        <div class="flex flex-col flex-auto lg:h-screen w-full sm:max-w-360 sm:shadow-lg sm:rounded-lg sm:overflow-hidden bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 justify-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden">
        <div class="flex items-center w-full max-w-360 h-14 sm:h-20">
            <span class="font-medium text-secondary">{{product_name}} &copy; {{currentYear}}</span>
        </div>
    </div>

</div>

<!-- Quick chat -->
<quick-chat *ngIf ="userInfo?.tenantInfo?.enable_chat" #quickChat="quickChat"></quick-chat>