<!-- Button -->
<button [matTooltip]="'Profile'" [matMenuTriggerFor]="userActions"
    class="relative p-0 border-0 bg-transparent focus:outline-none">
    <div class="w-14 h-14 rounded-full relative">
        <img *ngIf="showAvatar && user?.avatar" [src]="user?.avatar"
            class="w-full h-full rounded-full status-clr border-primary">

        <mat-icon *ngIf="!showAvatar || !user?.avatar" [svgIcon]="'heroicons_outline:user-circle'"
            class="w-full h-full rounded-full icon-size-9"></mat-icon>

        <div class="absolute bottom-0 right-0 flex items-center justify-center w-5 h-5 rounded-full"
            [style.background-color]="currentStatus.color">
            <i class="fa fa-pencil text-white text-xs"></i>
        </div>
    </div>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu"
    #userStatusMenu="matMenu">
    <button mat-menu-item (click)="settings()">
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user?.email_id}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <!-- <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button> -->
    <button mat-menu-item (click)="settings()">
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Settings</span>
    </button>
    <button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
        <span>Status</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signout()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
