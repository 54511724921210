import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { Message } from 'app/layout/common/messages/messages.types';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { catchError, map, Observable, of, ReplaySubject, Subject, switchMap, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessagesService {
    private _messages: ReplaySubject<Message[]> = new ReplaySubject<Message[]>(1);
    // messages: any;
    unreadCount: number = 0;
    user: any = {};
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    contacts: any;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        // private _overlay: Overlay,
        // private _viewContainerRef: ViewContainerRef,
        private _commonService: CommonService,
        private _snackbar: SnackbarService,
        private _authService: AuthService,
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for messages
     */
    get messages$(): Observable<Message[]> {
        return this._messages.asObservable();
    }

    /**
     * Fetch all messages and update the BehaviorSubject
     */
    getAll(): void {
        this.user = this._authService.contactInfo;

        this._commonService.getAllData(MODELS_CONSTANTS.CONTACTS).pipe(
            switchMap((result) => {
                if (result?.status === 200) {
                    this.contacts = result.data || [];
                    return this._commonService.getDataByField(MODELS_CONSTANTS.CHAT_MESSAGES, 'receiver_contact_id', this.user._id);
                } else {
                    throw new Error('Failed to fetch contacts');
                }
            }),
            map((response) => {
                if (response?.status === 200) {
                    return response.data.filter(item => !item.is_remove).reverse();
                } else {
                    throw new Error('Failed to fetch messages');
                }
            }),
            tap(async (messages) => {
                // Add sender names
                let chat_settings = [];
                const chat_response: any = await this._commonService.getDataByField(MODELS_CONSTANTS.CHAT_SETTINGS, 'contact_id', this.user._id).toPromise().catch(err => console.error(err));
                chat_settings = chat_response?.data;
                chat_settings = chat_settings.filter(item => item.muted || item.archived);
                messages = messages.filter(message =>
                    !chat_settings.some(setting => setting.chat_id === message.chat_id)
                );

                messages.forEach(message => {
                    const contact = this.contacts.find(contact => contact._id === message.sender_contact_id);
                    if (contact) {
                        message.name = contact.name;
                    }
                });

                // Emit updated messages
                this._messages.next(messages);
            }),
            catchError((err) => {
                console.error('Error in getAll:', err);
                return of([]);
            })
        ).subscribe();
    }

    updateMessage(message: Message, updates: Partial<Message>): Observable<any> {
        const updatedMessage = { ...message, ...updates };

        // Save the updated message record
        return this._commonService.saveRecord(MODELS_CONSTANTS.CHAT_MESSAGES, updatedMessage).pipe(
            tap(() => {
                // Calculate unread count (if relevant)
                this.getAll();
            }),
            catchError((err) => {
                console.error('Error updating message:', err);
                return of(null); // Return empty observable on error
            })
        );
    }

    /**
     * Create a message
     *
     * @param message
     */
    create(message: Message): Observable<Message> {
        return this.messages$.pipe(
            take(1),
            switchMap(messages => this._httpClient.post<Message>('api/common/messages', { message }).pipe(
                map((newMessage) => {
                    // Update the messages with the new message
                    this._messages.next([...messages, newMessage]);

                    // Return the new message from observable
                    return newMessage;
                }),
            )),
        );
    }

    /**
     * Update the message
     *
     * @param id
     * @param message
     */
    update(id: string, message: Message): Observable<Message> {
        return this.messages$.pipe(
            take(1),
            switchMap(messages => this._httpClient.patch<Message>('api/common/messages', {
                id,
                message,
            }).pipe(
                map((updatedMessage: Message) => {
                    // Find the index of the updated message
                    const index = messages.findIndex(item => item._id === id);

                    // Update the message
                    messages[index] = updatedMessage;

                    // Update the messages
                    this._messages.next(messages);

                    // Return the updated message
                    return updatedMessage;
                }),
            )),
        );
    }

    /**
     * Delete the message
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        return this.messages$.pipe(
            take(1),
            switchMap(messages => this._httpClient.delete<boolean>('api/common/messages', { params: { id } }).pipe(
                map((isDeleted: boolean) => {
                    // Find the index of the deleted message
                    const index = messages.findIndex(item => item._id === id);

                    // Delete the message
                    messages.splice(index, 1);

                    // Update the messages
                    this._messages.next(messages);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all messages as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.messages$.pipe(
            take(1),
            switchMap(messages => this._httpClient.get<boolean>('api/common/messages/mark-all-as-read').pipe(
                map((isUpdated: boolean) => {
                    // Go through all messages and set them as read
                    messages.forEach((message, index) => {
                        messages[index].read = true;
                    });

                    // Update the messages
                    this._messages.next(messages);

                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }
}
