import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SharedModule } from 'app/shared/shared.module';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-activity-tracker',
    templateUrl: './activity-tracker.component.html',
    styleUrls: ['./activity-tracker.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class ActivityTrackerComponent implements OnInit, OnChanges {
    @Input('form_data') form_data: any;
    formDetails: any[] = [];
    timelineView: 'vertical' | 'horizontal' = "vertical";
    taskList: any;
    users: any;
    formsData: any;

    constructor(
        private _commonService: CommonService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    toggletimelineView(view: 'vertical' | 'horizontal') {
        this.timelineView = view;
    }

    ngOnInit() {
        this.loadActivity();
        this.get_stage();
    }

    async loadActivity() {
        const response: any = await this._commonService
            .getDataByFields(MODELS_CONSTANTS.FORM_ACTIVITY_TRACKER, {
                form_id: this.form_data?.form_id,
                ref_id: this.form_data?.row_data?._id,
            })
            .toPromise();

        this.formDetails = response?.data || [];

        const userNames = {};
        for (let element of this.formDetails) {
            if (userNames?.[element?.done_by]) {
                element.done_by_user = userNames[element.done_by];
            } else {
                let response = await lastValueFrom(
                    this._commonService.getDataById(
                        MODELS_CONSTANTS.USERS,
                        element.done_by
                    )
                )
                    .then((response) => response?.data)
                    .catch((err) => console.error(err));

                if (response) {
                    element.done_by_user = response?.name;
                }

                userNames[element.done_by] = response?.name;
            }
            if (element.action == 'create') {
                element.title = 'Created';
            } else if (element.action == 'update') {
                element.title = 'Updated';
            }
        }
    }

    async get_stage() {
        try {
            const [users_res, forms_res] = await Promise.all([
                this._commonService.getAllData(MODELS_CONSTANTS.USERS).toPromise(),
                this._commonService.getDataByField(MODELS_CONSTANTS.FORM_SETP, '_id', this.form_data.form_id).toPromise()
            ]);

            if (users_res?.status === 200) {
                this.users = users_res?.data;
            }

            if (forms_res?.status === 200) {
                this.formsData = forms_res?.data;

                const form = this.formsData[0];

                console.log("All user IDs:", this.users.map(user => user._id));

                if (this.users && form) {
                    const created_user = this.users.find((obj) => obj._id === form.created_by);
                    if (created_user) {
                        form.created_by = created_user.name;
                    }
                    const updated_user = this.users.find((obj) => obj._id === form.updated_by);
                    if (updated_user) {
                        form.updated_by = updated_user.name;
                    }
                }
            }

            this._changeDetectorRef.markForCheck();

        } catch (error) {
            console.error("Error occurred:", error);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('form_data' in changes) {
            this.loadActivity();
        }
    }

    getIconForTitle(title: string): string {
        switch (title) {
            case 'Created':
                return 'star';
            case 'Approved':
                return 'thumb_up';
            case 'Status Updated':
                return 'verified_user';
            default:
                return 'help'; // Fallback icon
        }
    }
    getIconForColor(title: string): string {
        switch (title) {
            case 'Created':
                return 'Created';
            case 'Approved':
                return 'Approved';
            case 'Status Updated':
                return 'Status Updated';
            default:
                return 'black'; // Fallback icon
        }
    }
}
