<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden p-2">
  <div class="w-full flex flex-row workflow-modal-header sticky top-0 z-49 py-4 px-8 border-b">
    <div class="flex-1 min-w-0">
      <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
        All Activities
      </h2>

      <div class="text-secondary mt-1.5 text-lg"> Application wide activities are listed here as
        individual items,
        starting with the most recent. </div>
    </div>
    <div class="view-tabs w-fit ml-auto h-fit border rounded-full">
      <div class="example-flex-container bg-white border shadow-md rounded-full">
        <button matTooltip="Vertical View" mat-icon-button [ngClass]="{ 'bg-primary-200': timelineView === 'vertical' }"
          (click)="toggletimelineView('vertical')">
          <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 597.656 597.656"
            xml:space="preserve">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <g>
                  <path
                    d="M59.766,468.29v58.255c0,12.871,8.563,23.304,19.125,23.304h176.906V444.986H78.891 C68.329,444.986,59.766,455.419,59.766,468.29z">
                  </path>
                  <path
                    d="M98.016,334.3v58.255c0,12.871,8.563,23.304,19.125,23.304h138.656V311.001H117.141 C106.579,311.001,98.016,321.434,98.016,334.3z">
                  </path>
                  <path
                    d="M141.047,200.32v58.25c0,12.871,8.563,23.304,19.125,23.304h95.625V177.016h-95.625 C149.61,177.016,141.047,187.449,141.047,200.32z">
                  </path>
                  <path
                    d="M255.797,147.889V43.031h-47.812c-10.562,0-19.125,10.433-19.125,23.304v58.255c0,12.871,8.563,23.304,19.125,23.304 h47.812V147.889z">
                  </path>
                  <path
                    d="M341.859,549.844h176.906c10.562,0,19.125-10.433,19.125-23.304v-58.25c0-12.871-8.563-23.304-19.125-23.304H341.859 V549.844z">
                  </path>
                  <path
                    d="M341.859,415.858h138.656c10.562,0,19.125-10.433,19.125-23.304V334.3c0-12.871-8.563-23.304-19.125-23.304H341.859 V415.858z">
                  </path>
                  <path
                    d="M341.859,281.874h95.625c10.562,0,19.125-10.433,19.125-23.304v-58.25c0-12.871-8.563-23.304-19.125-23.304h-95.625 V281.874z">
                  </path>
                  <path
                    d="M408.797,124.585v-58.25c0-12.871-8.563-23.304-19.125-23.304h-47.812v104.857h47.812 C400.233,147.889,408.797,137.456,408.797,124.585z">
                  </path>
                  <rect x="279.703" width="38.25" height="597.656"></rect>
                </g>
              </g>
            </g>
          </svg>
        </button>
        <button matTooltip="Horizontal View" mat-icon-button
          [ngClass]="{ 'bg-primary-200': timelineView === 'horizontal' }" (click)="toggletimelineView('horizontal')">
          <svg fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 212.992 212.992" xml:space="preserve">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M60.734,113.996l24.158,0v39.493h15V59.502h-15v39.494l-24.158,0l-0.001-43.615L0,106.496l60.735,51.115L60.734,113.996z M23.295,106.496l22.438-18.885l0.002,37.771L23.295,106.496z M152.258,98.996l-24.158,0V59.502h-15v93.987h15v-39.493l24.158,0 l-0.001,43.615l60.735-51.115l-60.733-51.115L152.258,98.996z M167.259,87.611l22.438,18.885l-22.44,18.885L167.259,87.611z">
              </path>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <!-- <div class="flex flex-row"
    [ngClass]="{'top-info': timelineView == 'horizontal', 'top-right-info': timelineView == 'vertical'}">
    <div style="margin-right: 55px;font-weight: 600;" matTooltip="created_by"> {{ formsData[0].created_by || 'Unknown' }}<br></div>
    <div style="margin-right: 15px;font-weight: 600;" matTooltip="updated_by"> {{ formsData[0].updated_by || 'Unknown' }}</div>
  </div> -->

  <div class="overflow-auto">
    <div class="flex flex-auto flex-col px-6 sm:px-16 sm:pb-20 mb-10">
      <div class="w-full max-w-3xl">

        @if (timelineView === 'horizontal') {
        <div class="flex gap-x-5 items-center justify-center" style="height: calc(100% - 10rem);">
          <div *ngFor="let item of formDetails; let i = index"
            class="timeline-item flex ml-4 flex-col items-center  relative w-40 "
            [ngClass]="{'flex-col-reverse': i % 2 !== 0, 'ml-8': i === 1}"
            [style.left]="(i > 0 ? -1 * (i + 1.2)+ 'rem' : '0.3')">

            <div class="filter-info flex w-full flex-col items-center py-4"
              [ngClass]="{'timeline-text-down': i % 2 !== 0,'': i % 2 === 0 }">
              <div class="text-center text-2xl font-semibold leading-tight text-nowrap">
                {{ item.title }}
              </div>
              <div class="text-secondary text-xs text-center">
                by {{ item.done_by_user }} <br>
                {{ item.date_time | date: "'@' MMM d, yyyy - h:mm a" }}<br />
                Remarks : {{item.remarks}}
              </div>
            </div>

            <button mat-icon-button class="absolute timeline-icon shadow-lg">
              <mat-icon class="">{{ getIconForTitle(item.title) }}</mat-icon>
            </button>
            <svg version="1.1" viewBox="0 0 1768 1428" class="w-40  " xmlns="http://www.w3.org/2000/svg"
              style="left: -2rem; position: relative;" [ngClass]="{'timeline-svg-down  ':  i % 2 !== 0, 
                'icon-green': getIconForColor(item.title) === 'Created',
                'icon-blue': getIconForColor(item.title) === 'Approved',
                'icon-violet': getIconForColor(item.title) === 'Status Updated',
                'icon-black': getIconForColor(item.title) === 'black'
              }">
              <path transform="translate(1333,695)"
                d="m0 0h20l32 2 30 5 28 7 21 7 20 9 23 12 13 8 14 10 12 9 14 12 12 11 12 12 9 11 12 15 15 23 14 27 9 19 9 25 6 23 5 24 4 34v35l-4 34-6 29-7 25-7 19-12 25-10 18-8 12-8 11-13 17-12 13-22 22-11 9-13 10-20 13-15 9-28 13-27 10-23 6-31 5-24 2h-43l-27-2-28-5-22-6-21-8-25-11-15-8-20-13-15-11-10-8-14-12-22-22-9-11-11-14-14-21-10-17-9-19-8-19-8-24-6-27-4-27-2-26v-21l2-27 5-32 6-24 9-25 9-21 10-19 14-21 12-17 12-14 7-8 5-6 8-7 10-10 14-11 13-10 18-12 24-13 22-10 22-8 26-7 20-4 23-3z"
                fill="none" stroke="#e2e8f0" stroke-width="4">
              </path>
              <path stroke="none" stroke-width="4" transform="translate(1337,17)"
                d="m0 0h14l18 3 16 6 15 9 11 9 9 10 10 16 6 15 4 17v23l-5 21-8 16-9 12-8 9-13 10-10 6-20 8-1 1-1 11-1 164-3 122-1 92-3 2-48 3-28 4-23 5-29 8-20 7-26 11-19 9-18 10-23 14-10 7-15 11-11 9-13 11-8 7-20 20-7 8-13 16-11 14-16 24-7 11-12 21-14 29-11 26-8 26-7 30-6 35-1 7-1 34-1 10-4 2h-887l-3-1-1-6v-37l1-2 34-1h808l2-1 5-30 3-19 7-28 6-20 11-30 5-13 7-15 12-24 14-24 11-17 12-17 10-13 11-13 9-11 12-12 6-7 8-7 10-10 11-9 13-11 19-14 23-15 18-11 29-15 20-9 29-11 19-6 22-6 33-7 35-6h3l2-196 2-89v-58l-17-8-17-11-10-8-9-10-8-14-6-14-3-12-1-8v-18l3-16 3-10 8-15 7-9 9-10 8-8 15-9 12-5 11-3z">
              </path>
            </svg>
          </div>
        </div>
        }
        @else if (timelineView === 'vertical') {
        <div class="mb-5 mt-10 gap-y-10 w-full" style="text-align:  -webkit-center; ">
          <div *ngFor="let item of formDetails; let i = index" [ngClass]="{'flex-row-reverse left4-5': i % 2 !== 0}"
            class="timeline-item flex ml-4  items-center  relative h-40   ">

            <div [ngClass]="{'right-8 timelineInfo-right': i % 2 !== 0}"
              class="filter-info relatie w-60 flex-col items-center py-4">
              <div class="text-center text-2xl font-semibold leading-tight text-nowrap">
                {{ item.title }}
              </div>
              <div class="text-secondary text-xs text-center">
                by {{ item.done_by_user }} <br>
                {{ item.date_time | date: "'@' MMM d, yyyy - h:mm a" }}
              </div>
            </div>

            <div class="flex flex-col " [ngClass]="{'ml-20': i % 2 == 0}">
              <svg version="1.1" viewBox="0 0 1768 1428" class="w-40 rotate-90" xmlns="http://www.w3.org/2000/svg"
                style="left: -1.5rem; position: relative;" [ngClass]="{'flip-x -left-20': i % 2 == 0,
                  'icon-green': getIconForColor(item.title) === 'Created',
                  'icon-blue': getIconForColor(item.title) === 'Approved',
                  'icon-violet': getIconForColor(item.title) === 'Status Updated',
                  'icon-black': getIconForColor(item.title) === 'black'
                }">
                <path transform="translate(1333,695)"
                  d="m0 0h20l32 2 30 5 28 7 21 7 20 9 23 12 13 8 14 10 12 9 14 12 12 11 12 12 9 11 12 15 15 23 14 27 9 19 9 25 6 23 5 24 4 34v35l-4 34-6 29-7 25-7 19-12 25-10 18-8 12-8 11-13 17-12 13-22 22-11 9-13 10-20 13-15 9-28 13-27 10-23 6-31 5-24 2h-43l-27-2-28-5-22-6-21-8-25-11-15-8-20-13-15-11-10-8-14-12-22-22-9-11-11-14-14-21-10-17-9-19-8-19-8-24-6-27-4-27-2-26v-21l2-27 5-32 6-24 9-25 9-21 10-19 14-21 12-17 12-14 7-8 5-6 8-7 10-10 14-11 13-10 18-12 24-13 22-10 22-8 26-7 20-4 23-3z"
                  fill="none" stroke="#e2e8f0" stroke-width="4">
                </path>
                <path stroke="none" stroke-width="4" transform="translate(1337,17)"
                  d="m0 0h14l18 3 16 6 15 9 11 9 9 10 10 16 6 15 4 17v23l-5 21-8 16-9 12-8 9-13 10-10 6-20 8-1 1-1 11-1 164-3 122-1 92-3 2-48 3-28 4-23 5-29 8-20 7-26 11-19 9-18 10-23 14-10 7-15 11-11 9-13 11-8 7-20 20-7 8-13 16-11 14-16 24-7 11-12 21-14 29-11 26-8 26-7 30-6 35-1 7-1 34-1 10-4 2h-887l-3-1-1-6v-37l1-2 34-1h808l2-1 5-30 3-19 7-28 6-20 11-30 5-13 7-15 12-24 14-24 11-17 12-17 10-13 11-13 9-11 12-12 6-7 8-7 10-10 11-9 13-11 19-14 23-15 18-11 29-15 20-9 29-11 19-6 22-6 33-7 35-6h3l2-196 2-89v-58l-17-8-17-11-10-8-9-10-8-14-6-14-3-12-1-8v-18l3-16 3-10 8-15 7-9 9-10 8-8 15-9 12-5 11-3z">
                </path>
              </svg>
              <button mat-icon-button class="timeline-icon-vertical shadow-lg">
                <mat-icon class="">{{ getIconForTitle(item.title) }}</mat-icon>
              </button>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>