import { NgClass, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FuseConfig, FuseConfigService, Scheme, Theme, Themes } from '@fuse/services/config';
import { AuthService } from 'app/core/auth/auth.service';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';

import { MatSelectModule } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import { UserSettings } from './settings.types';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styles: [
        `
            settings {
                position: static;
                display: block;
                flex: none;
                width: auto;
            }

            @media (screen and min-width: 1280px) {

                empty-layout + settings .settings-cog {
                    right: 0 !important;
                }
            }
        `,
    ],
    exportAs: 'settings',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatIconModule, FuseDrawerComponent, MatButtonModule, NgFor, NgClass, MatTooltipModule, MatSelectModule],
})
export class SettingsComponent implements OnInit, OnDestroy {
    config: FuseConfig;
    user: any;
    layout: string;
    scheme: 'dark' | 'light';
    theme: string;
    themes: Themes;

    fontSizes: string[] = ['text-xs', 'text-sm', 'text-md', 'text-lg', 'text-xl'];
    defaultFontSizeIndex: number = 2; // Corresponds to 'text-md'

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _fuseConfigService: FuseConfigService,
        private _commonService: CommonService,
        private _snackbar: SnackbarService,
        private authService: AuthService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: FuseConfig) => {
                // Store the config
                this.config = config;
            });
        this.getUserSettings();

    }

   
    // Method to increase the font size on all elements
    increaseFontSize(): void {
        this.changeFontSize('increase');
    }

     // Method to decrease the font size on all elements
     decreaseFontSize(): void {
        this.changeFontSize('decrease');
    }
    
    private changeFontSize(action: 'increase' | 'decrease'): void {
     
        const elements = document.querySelectorAll('div, span, p, label, table, th, td');
    
        elements.forEach((element) => {
            const htmlElement = element as HTMLElement;
            if (htmlElement.classList.contains('header')) {
                const currentFontSize = window.getComputedStyle(htmlElement).fontSize;
                let fontSizeValue = parseFloat(currentFontSize); 
    
                if (action === 'increase') {
                    fontSizeValue += 5; 
                } else if (action === 'decrease' && fontSizeValue > 10) {
                    fontSizeValue -= 5;
                }
    
                htmlElement.style.fontSize = `${fontSizeValue}px`; 
            } else {
         
                let currentFontSizeClass = this.fontSizes.find((size) => htmlElement.classList.contains(size));
    
               
                if (!currentFontSizeClass) {
                    currentFontSizeClass = this.fontSizes[this.defaultFontSizeIndex];
                    htmlElement.classList.add(currentFontSizeClass);
                }
    
                
                let currentIndex = this.fontSizes.indexOf(currentFontSizeClass);
    
              
                if (action === 'increase' && currentIndex < this.fontSizes.length - 1) {
                    currentIndex++;
                } else if (action === 'decrease' && currentIndex > 0) {
                    currentIndex--;
                }
    
                htmlElement.classList.remove(currentFontSizeClass);
                htmlElement.classList.add(this.fontSizes[currentIndex]);
            }
        });
    }
    
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get User Settings
     */
    getUserSettings() {
        this.user = this.authService.userInfo;
        if (this.user != null) {
            this._commonService.getDataByField(MODELS_CONSTANTS.USER_SETTINGS, 'user_id', this.user._id).subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        if (!response?.data || !response?.data?.length) {
                            this.createSettings();
                        } else {
                            const { layout, scheme, theme, _id } = response?.data[0];
                            this.user.settings = response?.data[0];
                            this.user.settings._id = _id;
                            this.authService.userInfo = this.user;
                            this._fuseConfigService.config = {
                                layout,
                                scheme,
                                theme,
                            };
                        }
                    }
                },
                error: (err) => {
                    console.error(err);
                },
            });
        } else {
            this._fuseConfigService.config = {
                layout: 'classy',
                scheme: 'light',
                theme: 'theme-default'
            };
        }
    }

    createSettings() {
        const body = {
            layout: 'classy',
            scheme: 'light',
            theme: 'theme-default',
            user_id: this.user._id
        };
        this._commonService.saveRecord(MODELS_CONSTANTS.USER_SETTINGS, body).subscribe({
            next: (response: IResponse<UserSettings>) => {
                if (
                    response?.status === 200 &&
                    response?.data
                ) {
                    const { layout, scheme, theme, _id } = response?.data;
                    this.user.settings = response?.data;
                    this.authService.userInfo = this.user;
                    this._fuseConfigService.config = {
                        layout,
                        scheme,
                        theme,
                    };
                } else {
                    this._snackbar.error(response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                }
            },
            error: (err) => {
                console.error(err);
                this._snackbar.error(
                    MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                );
            },
        });
    }

    /**
     * Set the layout on the config
     *
     * @param layout
     */
    setLayout(layout: string): void {
        // Clear the 'layout' query param to allow layout changes
        this._router.navigate([], {
            queryParams: {
                layout: null,
            },
            queryParamsHandling: 'merge',
        }).then(() => {
            // Set the config
            this._fuseConfigService.config = { layout };
            this.user.settings.layout = layout;
            this.saveSettings();
        });
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void {
        this._fuseConfigService.config = { scheme };
        this.user.settings.scheme = scheme;
        this.saveSettings();
    }

    /**
     * Set the theme on the config
     *
     * @param theme
     */
    setTheme(theme: Theme): void {
        this._fuseConfigService.config = { theme };
        this.user.settings.theme = theme;
        this.saveSettings();
    }


     // Method to change the font size globally
     setFontSize(size: string): void {
        // Remove previous font size class from the body
        document.body.classList.remove('text-xs', 'text-md', 'text-lg');

        // Add the new font size class
        document.body.classList.add(size);

        // Optionally, save the setting to user preferences if needed
        this.user.settings.fontSize = size;
        this.saveSettings();
    }


    /**
     * Save settings
     */

    saveSettings() {
        this._commonService
            .saveRecord(MODELS_CONSTANTS.USER_SETTINGS, this.user.settings)
            .subscribe({
                next: (response: IResponse<any>) => {
                    if (response?.status === 200) {
                        console.log('response',response)
                      //  this._snackbar.success('Settings saved successfully');
                    } else {
                        this._snackbar.error(
                            response?.message ||
                            MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                        );
                    }
                },
                error: (err) => {
                    console.error(err);
                    this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
    }
}
