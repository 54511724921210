import { ChangeDetectorRef, Component, Inject, Input, Optional, TemplateRef, ViewChild } from '@angular/core';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { ScrumboardService } from 'app/modules/features/scrumboard/scrumboard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Board } from 'app/modules/features/scrumboard/scrumboard.models';
import { MatDrawer } from '@angular/material/sidenav';
import { COMPONENT_REFERENCE } from 'app/shared/constants/component-reference.constants';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash';
import { ListService } from 'app/modules/core/list/list.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { WORKFLOW_CONSTANTS } from 'app/shared/constants/workflow.constants';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-workflow-designer',
  templateUrl: './workflow-designer.component.html',
  styleUrls: ['./workflow-designer.component.scss'],
})
export class WorkflowDesignerComponent {
  @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
  @ViewChild('matDrawer', { static: true }) propertyMatDrawer: MatDrawer;
  @Input() row_data: any;
  @Input() parent_row_data: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  dialogRef: MatDialogRef<any>;
  stageTitle: any;
  private iconIndices: number[] = [];
  private bgColorIndices: number[] = [];
  icons: string[] = ['business', 'account_circle', 'assessment', 'work',
    'attach_money', 'bar_chart', 'build', 'supervisor_account',
    'trending_up', 'timeline'
  ];
  bgColors: string[] = ['bg-primary-50', 'bg-red-50', 'bg-green-50', 'bg-blue-50', 'bg-yellow-50', 'bg-purple-50', 'bg-pink-50', 'bg-indigo-50', 'bg-gray-50', 'bg-orange-50'];

  board: Board;
  board_approval_access: any;
  board_stages: any;
  board_tasks: any;
  dynamicTabsData: any;
  drawerWidth: string = '75%'
  receivedData: string;
  dynamicTabsComponent: { component: any };
  menuPermissions: any;
  stage_actions: any;
  apiRegistries: any;
  isActive = false;
  selectedToggle = 'hideActions';
  toggleFlag: boolean = false;
  public toggleTriggers: boolean[] = [];
  selectedEntryIndex: number | null = null;
  selectedPushIndex: number | null = null;
  selectedRejectIndex: number | null = null;
  selectedApproveIndex: number | null = null;
  selectedExitIndex: number | null = null;
  onEntryFlag: boolean = false;
  onEntryLength: any;
  workflowView: 'Vertical' | 'Horizontal' = "Vertical";
  workflowFlag: boolean;
  data: any;
  boards: any;

  public toggleSubMenu(index: number): void {
    this.toggleTriggers[index] = !this.toggleTriggers[index];
  }

  public onValChange(val: string) {
    this.getAllData();
    this.selectedToggle = val;
  }

  close_dialog = (data?: any) => {
    this.matDrawer?.close();
    this.getAllData();

    if (data) {
      this.row_data = data;
      this.getAllData();
      this.workflowFlag = true;
    }
  }

  close_property_drawer = (data?: any) => {
    this.propertyMatDrawer?.close();
    this.getAllData();

    if (data) {
      this.row_data = data;
      this.getAllData();
      this.workflowFlag = true;
    }
  }

  closeDialog() {
    if (this.data?.close_dialog) {
      this.data.close_dialog();
    } else if (this.row_data) {
      this.dialog.closeAll();
    }
    this.getAllData();
  }

  constructor(
    @Optional() @Inject(DIALOG_DATA) private config: any,
    private _commonService: CommonService,
    private _scrumboardService: ScrumboardService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _listService: ListService,
    public dialog: MatDialog,
    private _snackbar: SnackbarService,
    private _fuseConfirmationService: FuseConfirmationService,
  ) { }

  ngOnInit(): void {
    this.getAllData();
    this.loadAllData();
    this.stageTitle = this.row_data?.title
    this.toggleFlag = false;
  }

  async loadAllData(): Promise<void> {
    // Get permissions
    this._scrumboardService.permissions$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((menu: any) => {
        if (menu?.permissions) {
          this.menuPermissions = menu?.permissions;
          this._changeDetectorRef.markForCheck();
        }
      });

    this._scrumboardService.board$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((board: Board) => {
        this.board = { ...board };
        this._changeDetectorRef.markForCheck();
      });

    if (this.config) {
      this.data = this.config;
      if (this.config?.['row_data']) {
        this.row_data = this.config?.['row_data'];
      }
    }

    if (this.row_data && this.row_data._id) {
      this.getAllData();
      this.workflowFlag = true;
    } else {
      this.modifyBoardProperty();
    }

    this.stageTitle = this.row_data.title;
    this.toggleFlag = false;
  }

  ngAfterViewInit(): void {
    if (!this.row_data || !this.row_data._id) {
      this.propertyMatDrawer.open();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  async getAllData(): Promise<void> {
    try {
      // Fetch all API registries
      const response: any = await this._commonService.getAllData(MODELS_CONSTANTS.API_REGISTRIES).toPromise();
      if (response?.status === 200) {
        this.apiRegistries = response?.data;
      }

      // Get the board stages
      const stage_response: any = await this._scrumboardService.get_board_stages(this.row_data._id).toPromise();
      this.board_stages = stage_response;

      // Get the Stage Actions
      const stageAction_res: any = await this._commonService.getAllData(MODELS_CONSTANTS.STAGE_ACTIONS).toPromise();
      if (stageAction_res?.status === 200) {
        this.stage_actions = stageAction_res?.data;

        this.stage_actions.forEach(element => {
          if (element.action_type === 'api') {
            const apiName = this.apiRegistries.find(a => a._id === element.api);
            element.api_registry_name = apiName ? apiName.api_name : null;
          }
        });
        this._changeDetectorRef.markForCheck();
      }

      this.board_stages.forEach(object => {
        object.onEntryList = [];
        object.onApproveList = [];
        object.onRejectList = [];
        object.onPushbackList = [];
        object.onExitList = [];

        this.stage_actions.forEach(element => {
          if (object._id === element.stage_id) {
            if (element.action_type === 'api') {
              switch (element.stage_trigger) {
                case 'On Entry':
                  object.onEntryList.push(element);
                  break;
                case 'When Approved':
                  object.onApproveList.push(element);
                  break;
                case 'When Rejected':
                  object.onRejectList.push(element);
                  break;
                case 'On Pushback':
                  object.onPushbackList.push(element);
                  break;
                case 'On Exit':
                  object.onExitList.push(element);
                  break;
              }
            }
          }
        });
      });
      this.board_stages.sort((a, b) => a?.position - b?.position);
      this._changeDetectorRef.markForCheck();
      this.get_user_appoval();
      this.dynamicTabsComponent = COMPONENT_REFERENCE['dynamic-tabs'];

    } catch (error) {
      console.error(error);
    }
  }

  add_update_stage_task(stage = null) {
    this.dynamicTabsData = {
      form: {
        form_id: '6655cb3e6410429d10f6400c',
        id: stage && stage._id ? stage._id : null,
        name: 'Workflow',
        title: 'Board Stage',
        componentRef: 'form_render',
        close_dialog: this.close_dialog,
      },
      row_data: stage,
      parent_row_data: this.row_data,
      close_dialog: this.close_dialog,
    };

    this.dynamicTabsData = { ...this.dynamicTabsData }
    this.matDrawer.open();
  }

  modifyBoardProperty() {
    this.dynamicTabsData = {
      form: {
        form_id: '667282494c62475f62916765',
        id: this.row_data && this.row_data._id ? this.row_data._id : null,
        name: 'Board Property',
        title: 'Board Property',
        componentRef: 'form_render',
        close_dialog: this.close_property_drawer,
        isReturnAddData: true
      },
      row_data: this.row_data,
      parent_row_data: this.row_data,
      close_dialog: this.close_property_drawer
    };

    this.dynamicTabsData = { ...this.dynamicTabsData };
    this.propertyMatDrawer.open();
    this.row_data._id ? this.workflowFlag = true : this.workflowFlag = false
  }

  modifyBoardAccess() {
    this.dynamicTabsData = {
      form: {
        form_id: '6672835d4c62475f629171e6',
        id: this.row_data && this.row_data._id ? this.row_data._id : null,
        name: 'Board Access',
        title: 'Board Access',
        componentRef: 'form_render',
        close_dialog: this.close_dialog,
      },
      row_data: this.row_data,
      parent_row_data: this.row_data,
      close_dialog: this.close_dialog,
    };

    this.dynamicTabsData = { ...this.dynamicTabsData }
    this.matDrawer.open();
  }

  modifyBoardActions() {
    this.dynamicTabsData = {
      form: {
        form_id: '6672844e4c62475f62917dc9',
        id: this.row_data && this.row_data._id ? this.row_data._id : null,
        name: 'Board Actions',
        title: 'Board Actions',
        componentRef: 'form_render',
        close_dialog: this.close_dialog,
      },
      row_data: this.row_data,
      parent_row_data: this.row_data,
      close_dialog: this.close_dialog,
    };

    this.dynamicTabsData = { ...this.dynamicTabsData }
    this.matDrawer.open();
  }

  modifyStageProperty(property) {
    this.dynamicTabsData = {
      form: {
        form_id: '666c51317c75a80bf9ecec05',
        id: property && property._id ? property._id : null,
        name: 'Stage Property',
        title: 'Stage Property',
        componentRef: 'form_render',
        close_dialog: this.close_dialog,
      },
      row_data: property,
      parent_row_data: this.row_data,
      close_dialog: this.close_dialog,
    };

    this.dynamicTabsData = { ...this.dynamicTabsData }
    this.matDrawer.open();
  }

  modifyStagePermissions(permissions) {
    this.dynamicTabsData = {
      form: {
        form_id: '666c5f447c75a80bf9ecf39b',
        id: permissions && permissions._id ? permissions._id : null,
        name: 'Stage Permissions',
        title: 'Stage Permissions',
        componentRef: 'form_render',
        close_dialog: this.close_dialog,
      },
      row_data: permissions,
      parent_row_data: this.row_data,
      close_dialog: this.close_dialog,
    };

    this.dynamicTabsData = { ...this.dynamicTabsData }
    this.matDrawer.open();
  }

  modifyStageActions(stages = null, actions = null) {
    this.dynamicTabsData = {
      form: {
        form_id: '667903152c59a92753aaf007',
        id: actions && actions._id ? actions._id : null,
        name: 'Stage Actions',
        title: 'Stage Actions',
        componentRef: 'form_render',
        close_dialog: this.close_dialog,
      },
      row_data: actions,
      parent_row_data: stages ? stages : null,
      close_dialog: this.close_dialog,
    };

    this.dynamicTabsData = { ...this.dynamicTabsData }
    this.matDrawer.open();
  }

  deleteItem(item) {
    const confirmation = this._fuseConfirmationService.open({
      title: WORKFLOW_CONSTANTS.STAGE_DELETE_CONFIRMATION_TITLE,
      message: WORKFLOW_CONSTANTS.STAGE_DELETE_CONFIRMATION_CONTENT,
      actions: {
        confirm: {
          label: 'Delete',
        },
        cancel: {
          label: 'Cancel',
        },
      },
    });
    confirmation.afterClosed().subscribe(async (result) => {
      if (result === 'confirmed') {
        try {
          const response = await this._commonService.deleteRecordsById(MODELS_CONSTANTS.BOARD_STAGES, item?._id).toPromise();
          if (response?.status === 200) {
            this._snackbar.success(WORKFLOW_CONSTANTS.STAGE_DELETE_SUCCESS);
            this.getAllData();
          } else {
            this._snackbar.error(response?.message || WORKFLOW_CONSTANTS.TRY_AGAIN_LATER);
          }
        } catch (error) {
          console.error(error);
          this._snackbar.error(WORKFLOW_CONSTANTS.TRY_AGAIN_LATER);
        }
      }
    });
  }

  deleteAction(act) {
    const confirmation = this._fuseConfirmationService.open({
      title: WORKFLOW_CONSTANTS.ACTION_DELETE_CONFIRMATION_TITLE,
      message: WORKFLOW_CONSTANTS.ACTION_DELETE_CONFIRMATION_CONTENT,
      actions: {
        confirm: {
          label: 'Delete',
        },
        cancel: {
          label: 'Cancel',
        },
      },
    });
    confirmation.afterClosed().subscribe(async (result) => {
      if (result === 'confirmed') {
        try {
          const response = await this._commonService.deleteRecordsById(MODELS_CONSTANTS.STAGE_ACTIONS, act?._id).toPromise();
          if (response?.status === 200) {
            this._snackbar.success(WORKFLOW_CONSTANTS.ACTION_DELETE_SUCCESS);
            this.getAllData();
          } else {
            this._snackbar.error(response?.message || WORKFLOW_CONSTANTS.TRY_AGAIN_LATER);
          }
        } catch (error) {
          console.error(error);
          this._snackbar.error(WORKFLOW_CONSTANTS.TRY_AGAIN_LATER);
        }
      }
    });
  }

  updatePositions() {
    this.board_stages.forEach((stage, index) => {
      stage.position = index + 1;
    });
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.board_stages, event.previousIndex, event.currentIndex);
  }

  async get_user_appoval() {
    let approval_access = await this._commonService.getDataByFields(
      MODELS_CONSTANTS.BOARD_TASK_APPROVAL,
      {
        board_id: this.board._id
      }
    ).toPromise();
    if (approval_access?.status === 200 && approval_access?.data.length > 0) {
      this.board_approval_access = approval_access?.data;
    }
  }

  toggle_onEntry(index: number) {
    if (this.selectedEntryIndex === index) {
      this.selectedEntryIndex = null;
    } else {
      this.selectedEntryIndex = index;
    }
  }

  toggle_onPushback(index: number) {
    if (this.selectedPushIndex === index) {
      this.selectedPushIndex = null;
    } else {
      this.selectedPushIndex = index;
    }
  }

  toggle_onReject(index: number) {
    if (this.selectedRejectIndex === index) {
      this.selectedRejectIndex = null;
    } else {
      this.selectedRejectIndex = index;
    }
  }

  toggle_onApprove(index: number): void {
    if (this.selectedApproveIndex === index) {
      this.selectedApproveIndex = null;
    } else {
      this.selectedApproveIndex = index;
    }
  }

  toggle_onExit(index: number): void {
    if (this.selectedExitIndex === index) {
      this.selectedExitIndex = null;
    } else {
      this.selectedExitIndex = index;
    }
  }

  iconsAndFlagsHorizontal = [
    { icon: 'visibility_off', isActive: true, tooltip: 'Hide Action Icons' },
    { icon: 'remove_red_eye', isActive: false, tooltip: 'Show Action Icons' },
    { icon: 'dashboard', isActive: false, tooltip: 'Show Actions Cards' },
    { icon: 'cancel', isActive: false, tooltip: 'Hide Actions Cards' }
  ];
  currentIndexHorizontal = 0;

  iconsAndFlagsVertical = [
    { icon: 'visibility_off', isActive: true, tooltip: 'Hide Action Icons' },
    { icon: 'remove_red_eye', isActive: false, tooltip: 'Show Action Icons' },
    { icon: 'dashboard', isActive: false, tooltip: 'Show Actions Cards' },
    { icon: 'cancel', isActive: false, tooltip: 'Hide Actions Cards' }
  ];
  currentIndexVertical = 0;

  get currentIconAndTooltip() {
    if (this.workflowView === 'Horizontal') {
      return this.iconsAndFlagsHorizontal[this.currentIndexHorizontal];
    } else if (this.workflowView === 'Vertical') {
      return this.iconsAndFlagsVertical[this.currentIndexVertical];
    }
    return { icon: '', tooltip: '' }; // Fallback
  }

  toggleActive() {
    if (this.workflowView === 'Horizontal') {
      // Handle Horizontal view
      const currentIndex = this.currentIndexHorizontal;
      const icons = this.iconsAndFlagsHorizontal;

      icons[currentIndex].isActive = false;

      if (currentIndex === icons.length - 1) {
        this.currentIndexHorizontal = 0;
      } else {
        this.currentIndexHorizontal += 1;
      }

      icons[this.currentIndexHorizontal].isActive = true;
    }
    else if (this.workflowView === 'Vertical') {
      // Handle Vertical view
      const currentIndex = this.currentIndexVertical;
      const icons = this.iconsAndFlagsVertical;

      icons[currentIndex].isActive = false;

      if (currentIndex === icons.length - 1) {
        this.currentIndexVertical = 0;
      } else {
        this.currentIndexVertical += 1;
      }

      icons[this.currentIndexVertical].isActive = true;
    }
  }

  // add_update_stage_task(stage = null, index: number = null) {
  //   let position: number;

  //   if (stage?.position !== undefined && stage?.position !== null) {
  //     position = stage.position; // Existing position
  //   } else if (index !== null) {
  //     position = index; // Current index when adding between stages
  //   } else {
  //     position = 0; // Default to 0 for the first stage
  //   }

  //   console.log('stage check', stage);
  //   const parent_row_data = cloneDeep(this.row_data);

  //   if (!stage) {
  //     stage = { position }; // Initialize stage with the position
  //   } else {
  //     stage.position = position; // Update existing stage with new position
  //   }

  //   // Ensure row_data.position is set correctly
  //   parent_row_data.position = position;

  //   if (index !== null) {
  //     // Insert stage at index + 1 and update positions
  //     this.board_stages.splice(index + 1, 0, stage);
  //   } else {
  //     // Add stage to the end and update positions
  //     this.board_stages.push(stage);
  //   }

  //   this.updatePositions(); // Update positions after modification

  //   this.dynamicTabsData = {
  //     form: {
  //       form_id: '6655cb3e6410429d10f6400c',
  //       id: stage._id ? stage._id : null,
  //       name: 'Workflow',
  //       title: stage._id ? 'Edit Board Stage' : 'Add Board Stage',
  //       componentRef: 'form_render',
  //       close_dialog: this.close_dialog,
  //     },
  //     row_data: stage,
  //     parent_row_data: parent_row_data,
  //     close_dialog: this.close_dialog,
  //   };

  //   this.dynamicTabsData = { ...this.dynamicTabsData };
  //   console.log('this.dynamicTabsData', this.dynamicTabsData);
  //   this.matDrawer.open();
  // }

}  
