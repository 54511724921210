import {
    Component,
    Input,
    OnInit,
    Inject,
    ChangeDetectorRef,
    TemplateRef,
    Optional,
    OnChanges,
    SimpleChanges,
    WritableSignal,
    signal
} from '@angular/core';
import { FormioRefreshValue } from '@formio/angular';
import { ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { DynamicFormRendererComponent } from 'app/modules/common/forms/forms-renderer/forms-renderer.component';
import { map, Observable, startWith } from 'rxjs';

@Component({
    selector: 'app-builder',
    templateUrl: './forms-builder.component.html',
    styleUrls: ['./forms-builder.component.scss'],
})
export class DynamicFormsBuilderComponent implements OnInit, OnChanges {
    @ViewChild('prompt_dialog') prompt_dialog: TemplateRef<any>;
    @ViewChild('confirm_dialog') confirm_dialog: TemplateRef<any>;
    @ViewChild('confirm_module_dialog') confirm_module_dialog: TemplateRef<any>;
    module_dialog_ref: MatDialogRef<any>;
    dialogRef: MatDialogRef<any>;
    @ViewChild('json', { static: true }) jsonElement?: ElementRef;
    @ViewChild('code', { static: true }) codeElement?: ElementRef;
    public form: Object;
    public current_form_data: any;
    public refreshForm: EventEmitter<FormioRefreshValue> = new EventEmitter();
    @Input() data: any;
    FormHeader = 'Add Form';
    error: string;
    modules_array: any;
    api_registries: any;
    boards: any;
    version_list: any;
    formFrom: FormGroup = this.fb.group({
        form_code: ['', [Validators.required]],
        form_name: ['', [Validators.required]],
        module_id: [''],
        init_action: ['', []],
        current_version: [1, [Validators.required]],
        parent_id: [''],
        is_approval: [false],
        form_type: [''],
        board_id: [''],
        status: [true],
    });
    promtForm: FormGroup = this.fb.group({
        // forms_data: [''],
        prompt_text: ['', [Validators.required]],
        module_code: [''],
        is_override_settings: [false],
        is_remove_fields: [false],
    });
    configForm: FormGroup;
    promptDialogRef: MatDialogRef<any, any>;
    isPromptSubmitDisabled: boolean = false;
    filteredModules: Observable<any[]>;
    showLoader: WritableSignal<boolean> = signal(true);

    filteredModulesArray: any[] = []; 
    moduleFilterControl: FormControl = new FormControl('');
    filteredapiArray: any[] = []; 
    apiFilterControl: FormControl = new FormControl('');

    constructor(
        public route: Router,
        public activeroute: ActivatedRoute,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private fb: FormBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private _commonService: CommonService,
        private snackbar: SnackbarService,
        private dialog: MatDialog
    ) {
        if (this.dialogData && this.dialogData?.title) {
            this.data = this.dialogData;
        }
    }

    ngOnInit(): void {

        // this.get_modules();

        // this.moduleFilterControl.valueChanges.subscribe((searchValue: string) => {
        //   this.filteredModulesArray = this._filterActiveModules(searchValue);
        // });

        this.moduleFilterControl.valueChanges.subscribe((searchValue: string) => {
            const selectedValue = this.formFrom.get('module_id')?.value;
            
            this.filteredModulesArray = this._filterActiveModules(searchValue);
            
            if (selectedValue) {
              const selectedModules = this.modules_array.find((item: any) => item.module_id === selectedValue);
              if (selectedModules && !this.filteredModulesArray.includes(selectedModules)) {
                this.filteredModulesArray.unshift(selectedModules); 
              }
            }
          });


        this.apiFilterControl.valueChanges.subscribe((searchValue: string) => {
            const selectedValue = this.formFrom.get('init_action')?.value;
            
            this.filteredapiArray = this._filterActiveApi(searchValue);
            
            if (selectedValue) {
              const selectedApi = this.api_registries.find((item: any) => item._id === selectedValue);
              if (selectedApi && !this.filteredapiArray.includes(selectedApi)) {
                this.filteredapiArray.unshift(selectedApi);
              }
            }
          });

        this.loadData();
        this.version_list = [{ current_version: 1 }];
        this.formFrom.get('current_version').valueChanges.subscribe(value => {
            let changed_version = this.version_list.find(v => v.current_version === value);
            if (changed_version && changed_version.form_data) {
                this.form = changed_version.form_data
            }
        });
        this.filteredModules = this.promtForm.get('module_code').valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            this.loadData();
        }
    }

    private _filterActiveApi(searchValue: string): any[] {
        const filterValue = searchValue ? searchValue.toLowerCase() : '';
    
        if (!filterValue) {
          return this.api_registries;
        }
    
        return this.api_registries.filter((module: any) => 
          module.api_name.toLowerCase().includes(filterValue)
        );
      }

      onDropdownOpened(isOpened: boolean) {
        if (isOpened) {
          this.filteredModulesArray = this.modules_array;
          this.filteredapiArray = this.api_registries;
      
          this._changeDetectorRef.detectChanges();
        } else {
          this.moduleFilterControl.setValue('');
          this.apiFilterControl.setValue('');
        }
      }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
    
        return this.modules_array.filter(option => option?.module_code?.toLowerCase()?.includes(filterValue));
    }

    private _filterActiveModules(searchValue: string): any[] {
        const filterValue = searchValue ? searchValue.toLowerCase() : '';
    
        if (!filterValue) {
          return this.modules_array;
        }
    
        return this.modules_array.filter((module: any) => 
          module.module_code.toLowerCase().includes(filterValue)
        );
      }

    async loadData() {
        this.showLoader.set(true)
        this.form = { components: [] };
        this.FormHeader = this.data.title;
        if (this.data.id) {
            await this.get_form_by_id(this.data.id);
        }
        if (this.data.selected_module) {
            this.formFrom.patchValue({
                module_id: this.data.selected_module
            });
        }
        if (this.data.schema) {
            await this.load_schema_fields(this.data.schema);
        }
        await this.get_API_registries();
        await this.get_modules();
        await this.get_boards();
        setTimeout(() => {
            this.showLoader.set(false);
        }, 1000);
    }

    async get_API_registries() {
        try {
            const result = await this._commonService.getAllData(MODELS_CONSTANTS.API_REGISTRIES).toPromise();
            if (result?.status === 200) {
                this.api_registries = result?.data.sort((a: any, b: any) => 
                    a.api_name.localeCompare(b.api_name)
                  );

                this.filteredapiArray= this.api_registries;
                this._changeDetectorRef.markForCheck();
            }
        } catch (error) {
            console.error(error);
        }
    }

    async get_modules() {
        try {
            const result = await this._commonService.getAllData(MODELS_CONSTANTS.MODULES).toPromise();
            if (result?.status === 200) {
                this.modules_array = result?.data.sort((a: any, b: any) => 
                    a.module_code.localeCompare(b.module_code)
                  );

                  this.filteredModulesArray= this.modules_array;
                  this._changeDetectorRef.markForCheck();
            }
        } catch (error) {
            console.error(error);
        }
    }

    async get_boards() {
        try {
            const result = await this._commonService.getAllData(MODELS_CONSTANTS.SCRUMBOARDS).toPromise();
            if (result?.status === 200) {
                this.boards = result?.data;
                this._changeDetectorRef.markForCheck();
            }
        } catch (error) {
            console.error(error);
        }
    }

    async get_form_by_id(id) {
        try {
            const result = await this._commonService.getDataById(MODELS_CONSTANTS.FORM_SETP, id).toPromise();
            if (result?.status === 200) {
                this.get_form_version(result.data.form_code);
                this.formFrom.patchValue({
                    ...result.data,
                });
                this.form = result.data.form_data;
                this.current_form_data = result.data;
                this.form = { ...this.form };
            }
        } catch (error) {
            console.error(error);
        }
    }

    get_form_version(form_code) {
        this._commonService
            .getDataByFields(MODELS_CONSTANTS.FORM_SETP_VERSION, { form_code })
            .subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this.version_list = response.data;
                    }
                },
                error: (err) => {
                    console.error(err);
                },
            });
    }

    save_form() {
        if (this.formFrom.valid) {
            let form_data: any = this.form;
            let last_comp = form_data.components[form_data.components.length - 1];
            if(last_comp && last_comp.key && last_comp.key == 'submit'){
                form_data.components.splice(form_data.components.length - 1, 1)
            }
            const formData = this.formFrom.value;
            formData['init_action'] = formData['init_action'] ? formData['init_action'] : "";
            formData['parent_id'] = formData['parent_id'] ? formData['parent_id'] : "";
            formData['module_id'] = formData['module_id'] ? formData['module_id'] : "";
            formData['status'] = formData?.status ? 1 : 0;
            formData['form_data'] = form_data;
            if(formData['form_type'] == 'custom') {
                formData['is_approval'] = false
            }
            if (this.data.id) {
                this.update_confirm(formData);
            } else {
                this.create(formData);
            }
        } else {
            this.snackbar.warning('Please fill the form');
        }
    }

    create(formData : any) {
        if(!formData.module_id){
            this.dialogRef = this.dialog.open(this.confirm_module_dialog, {
                autoFocus: true,
                width: '80vw',
                maxHeight: '90vh',
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    if (result === 'new') {
                        const components = formData.form_data.components || [];
                        let schema = this.extractSchema(components);
                        schema = this.customStringify(schema)
                        this.open_new_module_form(schema)
                    }
                    if (result === 'old') {
                        this.snackbar.warning('Please select module');
                    }
                }
            });
        } else {
            this._commonService
            .saveRecord(MODELS_CONSTANTS.FORM_SETP, formData)
            .subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this.snackbar.success(`Form added successfully`);
                        this.create_version({
                            form_code: formData.form_code,
                            form_data: formData.form_data,
                            current_version: formData.current_version,
                        });
                        this.data.close_dialog(formData);
                    } else {
                        this.snackbar.error(
                            response?.message ||
                            MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                        );
                    }
                },
                error: (err) => {
                    this.snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
        };
    }

    open_new_module_form(schema: any) {
        this.module_dialog_ref = this.dialog.open(
            DynamicFormRendererComponent,
            {
                autoFocus: false,
                maxWidth: '100vw',
                width: '100%',
                height: '100%',
                maxHeight: '100vh',
                panelClass: 'full-screen-dialog',
                data: {
                    title: 'Add New Module',
                    form_code: 'modules',
                    module_schema : schema,
                    close_dialog: this.close_module_form,
                    isReturnAddData : true
                },
            }
        );
    }

    close_module_form = async (data) => {
        if (data) {
            await this.get_modules();
            this.formFrom.patchValue({
                module_id: data.module_id,
            });
            this.module_dialog_ref.close();
            this.save_form()
        }
    };

    update_confirm(formData) {
        this.dialogRef = this.dialog.open(this.confirm_dialog, {
            autoFocus: true,
            width: '80vw',
            maxHeight: '90vh',
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (result === 'update') {
                    if (this.current_form_data.current_version === formData.current_version) {
                        this.update(formData)
                    }
                    this.update_version({
                        form_code: formData.form_code,
                        form_data: formData.form_data,
                        current_version: formData.current_version,
                    });
                }
                if (result === 'new') {
                    this.create_version({
                        form_code: formData.form_code,
                        form_data: formData.form_data,
                        current_version: this.version_list.length + 1
                    });
                }
                if (result === 'update&avtive') {
                    this.update(formData);
                    this.update_version({
                        form_code: formData.form_code,
                        form_data: formData.form_data,
                        current_version: formData.current_version,
                    });
                }
                if (result === 'new&active') {
                    formData['current_version'] = this.version_list.length + 1;
                    this.update(formData);
                    this.create_version({
                        form_code: formData.form_code,
                        form_data: formData.form_data,
                        current_version: formData.current_version,
                    });
                }
                this.data.close_dialog(formData);
            }
        });
    }

    update(formData) {
        this._commonService
            .updateRecordsByFields(
                MODELS_CONSTANTS.FORM_SETP,
                { _id: this.data.id },
                formData
            )
            .subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this.snackbar.success(`Form modified successfully`);
                    } else {
                        this.snackbar.error(
                            response?.message ||
                            MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                        );
                    }
                },
                error: (err) => {
                    this.snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
    }

    create_version(formData) {
        this._commonService
            .saveRecord(MODELS_CONSTANTS.FORM_SETP_VERSION, formData)
            .subscribe({});
    }

    update_version(formData) {
        this._commonService
            .updateRecordsByFields(
                MODELS_CONSTANTS.FORM_SETP_VERSION,
                {
                    form_code: formData.form_code,
                    current_version: formData.current_version,
                },
                formData
            )
            .subscribe({});
    }

    close() {
        this.data.close_dialog();
    }

    load_schema_fields(schema: any) {
        let components = []
        Object.keys(schema).forEach(key => {
            const field = schema[key];
            let component = null;
            switch (field.type) {
                case 'string':
                    component = {
                        type: 'textfield',
                        key: key,
                        label: key,
                        required: field.required ? true : false,
                        defaultValue: field.default || ''
                    };
                    break;
                case 'number':
                    component = {
                        type: 'number',
                        key: key,
                        label: key,
                        required: field.required ? true : false,
                        defaultValue: field.default || 0
                    };
                    break;
                case 'Date':
                    component = {
                        type: 'datetime',
                        key: key,
                        label: key,
                        format: 'yyyy-MM-dd',
                        defaultValue: new Date().toISOString()
                    };
                    break;
                default:
                    component = {
                        type: 'textfield',
                        key: key,
                        label: key,
                        required: field.required ? true : false,
                        defaultValue: field.default || ''
                    };
            }
            components.push(component)
        });
        components.push({
            type: 'button',
            label: 'Submit',
            key: 'submit',
            action: 'submit',
        });
        this.form = { components };
        this.form = { ...this.form };
    }

    mapTypeToSchemaType(type: string): any {
        switch (type) {
            case 'textfield':
            case 'textarea':
            case 'email':
            case 'phoneNumber':
                return { type: 'String' };
            case 'number':
                return { type: 'Number' };
            case 'checkbox':
                return { type: 'Boolean' };
            case 'datetime':
                return { type: 'Date' };
            case 'select':
                return [{ type: 'String' }];
            case 'file':
                return [{ type: 'Buffer' }];
            case 'datagrid':
            case 'columns':
            case 'tabs':
            case 'table':
            case 'panel':
            case 'well':
                return [{ type: 'Mixed' }];
            default:
                return { type: 'String' };
        }
    }
    
    extractSchema(components: any[]): any {
        const schema: any = {};
    
        for (const component of components) {
            if (component.key && component.type) {
                schema[component.key] = this.mapTypeToSchemaType(component.type);
            }
    
            if (component.columns) {
                for (const column of component.columns) {
                    Object.assign(schema, this.extractSchema(column.components));
                }
            } else if (component.tabs) {
                for (const tab of component.tabs) {
                    Object.assign(schema, this.extractSchema(tab.components));
                }
            } else if (component.fieldset) {
                Object.assign(schema, this.extractSchema(component.fieldset));
            } else if (component.type === 'table') {
                for (const row of component.rows) {
                    for (const tableComponent of row) {
                        Object.assign(schema, this.extractSchema([tableComponent]));
                    }
                }
            } else if (component.type === 'panel') {
                Object.assign(schema, this.extractSchema(component.components));
            } else if (component.type === 'datagrid') {
                schema[component.key] = [{ type: 'Mixed' }];
            } else if (component.components) {
                Object.assign(schema, this.extractSchema(component.components));
            }
        }
    
        return schema;
    }
        
    customStringify(schema: any): string {
        let result = '{\n';
    
        for (const [key, value] of Object.entries<any>(schema)) {
            result += `    ${key}: { type: ${value.type} },\n`;
        }
    
        // Remove the trailing comma and newline
        if (result.endsWith(',\n')) {
            result = result.slice(0, -2) + '\n';
        }
    
        result += '}';
        return result;
    }

    openPrompt() {
        this.promtForm?.reset({is_override_settings: false, is_remove_fields: false});
        this.promptDialogRef = this.dialog.open(this.prompt_dialog, {
            autoFocus: false,
            width: '60vw',
            maxHeight: '90vh',
        });
    }

    closeDialog() {
        this.promptDialogRef.close();
    }

    async executePrompt() {
        if (this.promtForm.valid && this.promtForm.value['prompt_text']) {
            this.showLoader.set(true);
            try {
                this.isPromptSubmitDisabled = true;
                const formData = this.promtForm.value;
                // let json: any = {
                //     prompt_text: formData.prompt_text,
                //     is_override_settings: formData.is_override_settings,
                //     is_remove_fields: formData.is_remove_fields,
                //     isDelete: false,
                //     forms_data: "",
                //     status: formData.status ? 1 : 0
                // };

                // if (this.data && this.data.row_data) {
                //     json.forms_data = this.data.row_data;
                // }
                // console.log("JSON data to be saved:", json);

                // if (Object.keys(json).length > 0) {
                //     this.snackbar.success('Execution is done, and the data is stored in JSON format.');
                // } else {
                //     this.snackbar.warning('No data to save in JSON format.');
                // }

                const { prompt_text: instructions, module_code, ...rest } = formData;

                const requestBody = {
                    instructions,
                    conditions: rest,
                    form_data: this.form || null
                }
                if(module_code){
                    requestBody['module_code'] = module_code;
                }
                this.promptDialogRef?.close();

                const response = await this._commonService.executeFormsAI(requestBody).toPromise();

                if(response?.data && response?.data?.components) {
                    this.form = response?.data;
                }
                
                this.isPromptSubmitDisabled = false;
                setTimeout(() => {
                    this.showLoader.set(false);
                }, 500);
            } catch (error) {
                this.isPromptSubmitDisabled = false;
                console.error(error);
                this.showLoader.set(false);
                this.snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            }
        } else {
            this.snackbar.warning('Prompt Instructions is required');
        }
    }
}
