import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SocketService } from 'app/shared/services/socket/socket.service';
import { catchError, map, Observable, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _commonService: CommonService, private _authService: AuthService, private _socketService: SocketService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        this.getAll();
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll() {
        let user = this._authService.userInfo;
        this._commonService.getDataByField(MODELS_CONSTANTS.NOTIFICATIONS, 'user_id', user._id).subscribe({
            next: (notifications: any) => {
                if (notifications?.status === 200) {
                    notifications?.data.reverse();
                    this._notifications.next(notifications?.data);
                }
            },
            error: (err) => {
                return err;
            },
        });
    }

    add(body, data) {
        const notification = {
            form_id: body.form_id,
            ref_id: body.ref_id,
            title: body.action == 'update' ? `${data?.title} Updated Successfully` : `${data?.title} Created Successfully`,
            description: body.action == 'update' ? `${data?.title} has been updated successfully` : `${data?.title} has been created successfully`,
            icon_type: body.action == 'update' ? 'heroicons_outline:pencil' : 'heroicons_outline:check',
            link: `/core/list/${data.list_view_id}`,
            user_id: body.done_by,
            read: false,
        }
        this.addNotification(notification);
    }

    addNotification(notification) {
        this._commonService.saveRecord(MODELS_CONSTANTS.NOTIFICATIONS, notification)
        .subscribe({
            next: () => { 
                const contactInfo = JSON.parse(localStorage.getItem('contact_info') || '{}');
                this._socketService.sendMessage(
                    contactInfo?._id, ''
                );
            },
            error: (saveError) => {
                console.error(saveError);
                // this.snackbar.error(saveError?.messge || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            }
        });
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', { notification }).pipe(
                map((newNotification) => {
                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                }),
            )),
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification): Observable<Notification> {
        return this._commonService.saveRecord(MODELS_CONSTANTS.NOTIFICATIONS, notification).pipe(
            tap((notifications: any) => {
                if (notifications?.status === 200) {
                    const index = notifications.findIndex(item => item?._id === id);

                    // Update the notification
                    this._notifications[index] = notification;

                    // Update the notifications
                    this._notifications.next(notifications);
                }
            }),
            map(() => notification),
            catchError(err => {
                // Handle error
                return throwError(err);
            })
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', { params: { id } }).pipe(
                map((isDeleted: boolean) => {
                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item?._id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
                map((isUpdated: boolean) => {
                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) => {
                        notifications[index].read = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }
}
