<div style="width: -webkit-fill-available;" class="h-full relative P-2">
    <button *ngIf="tabsData.length == 1 && !is_menu && show_close"  mat-icon-button class="absolute z-20 btn-pos flex flex-row add-api-modal-header ml-auto flex cencel-dialog-icon" [matTooltip]="'Close'" (click)="closeDialog()">
        <mat-icon class="api-primary-btn cursor-pointer">close</mat-icon>
    </button>
    <div *ngIf="tabPosition == 'Top'" class="top-tabs-container">
        <div class="h-full w-full overflow-hidden" [style]="!is_menu ? 'padding-top: 10px !important' : ''" id="nav-container">
            <div  class="flex">
                <button *ngIf="tabsData.length > 1 && (tabProperties === 'type1' || tabProperties === 'type2')" class="btn bg-warn z-10" matTooltip="Back to {{ tabsData[0]?.name }}" (click)="closeDialog()">
                    <mat-icon class="back-icon icon-size-5 mt-1" matPrefix [svgIcon]="'heroicons_solid:arrow-uturn-left'">
                    </mat-icon>
                </button>
                <div class="flex border-l ml-0.5">
                    <button *ngIf="tabsData.length > 1 &&  tabProperties === 'type2'" [matTooltip]="'Previous Tab'" class="btn border btn-primary z-10 " (click)="activatePreviousTab()">
                        <mat-icon class="icon-size-5 mt-1" matPrefix [svgIcon]="'heroicons_solid:chevron-double-left'">
                        </mat-icon>
                    </button>
                    <button *ngIf="tabsData.length > 1 && tabProperties === 'type2' " [matTooltip]="'Next Tab'" class="btnb border btn-primary z-10 w-12 ml-0.5 mr-0.5 rounded" (click)="activateNextTab()">
                        <mat-icon class="mt-1 icon-size-5" matPrefix [svgIcon]="'heroicons_solid:chevron-double-right'">
                        </mat-icon>
                    </button>
                </div>
                <ul class="w-full nav pr-0 border-b-0 flex-row flex-nowrap overflow-x-hidden overflow-y-hidden ease-in-out duration-300"
                    id="nav-tabs">
                    <ng-container *ngFor="let tab of tabsData; let index = index; let isEven = even">
                        <li *ngIf="tabsData.length > 1" #tabElement
                            class="nav-item tab-list w-full px-0 bg-white border-r border-l border cursor-pointer"
                            [ngClass]="{'border-l': isEven, 'active  border-y-0': tab?.isActive}">
                            <a class="nav-link a-padd" data-toggle="tab"
                                [ngClass]="{'active border-0': tab?.isActive}"
                                (click)="loadDynamicComponent(tab, tabElement)">
                                <div class="icon-flex m-auto">
                                    <div *ngIf="tab?.icon" class="icon-radius"
                                        [ngClass]="{'active ': tab?.isActive}">
                                        <mat-icon [svgIcon]="tab?.icon" class="icon-color"
                                            [ngClass]="{'active': tab?.isActive}"></mat-icon>
                                    </div>
                                    <div class="label-block m-auto mr-2 w-full" #sentenceRef (mouseenter)="checkOverflow(sentenceRef)"
                                                [matTooltip]="isOverflow ? tab?.name: ''" >
                                        <div>{{ tab?.name }}</div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ng-container>
                </ul>
                <div class="flex border-l ml-0.5">
                    <button *ngIf="tabsData.length > 1 && (tabProperties === 'type1' || tabProperties === 'type3')" [matTooltip]="'Previous Tab'" class="btn border btn-primary z-10" (click)="activatePreviousTab()">
                        <mat-icon class=" icon-size-5 mt-1" matPrefix [svgIcon]="'heroicons_solid:chevron-double-left'">
                        </mat-icon>
                    </button>
                    <button *ngIf="tabsData.length > 1 && (tabProperties === 'type1'||tabProperties === 'type3') " [matTooltip]="'Next Tab'" class="btnb border btn-primary z-10 w-12 ml-0.5 mr-0.5 rounded" (click)="activateNextTab()">
                        <mat-icon class="mt-1 icon-size-5" matPrefix [svgIcon]="'heroicons_solid:chevron-double-right'">
                        </mat-icon>
                    </button>
                </div>
                <button *ngIf="tabsData.length > 1 && tabProperties === 'type3' " class="btn z-10 bg-warn" matTooltip="Back to {{ tabsData[0]?.name }}" (click)="closeDialog()">
                    <mat-icon class=" back-icon icon-size-5 mt-1" matPrefix [svgIcon]="'heroicons_solid:arrow-uturn-left'">
                    </mat-icon>
                </button>
                
            </div>
            <div class="tab-content col-12 b-2  overflow-y-auto" [ngClass]="{'mt-0': is_menu}" [style]="is_menu ? 'height: 100% !important' : ''" id="nav-content">
                <div class="container tab-pane active pr-0 pl-0">
                    <ng-container *ngFor="let tab of tabsData">
                        <div [ngClass]="{'hidden': !tab?.isActive}">
                            <ng-container *ngComponentOutlet="tab?.component?.component; inputs: tab?.componentInputs" class=" overflow-y-auto">
                            </ng-container>
                        </div>
                    </ng-container>
                    <!-- <ng-container *ngComponentOutlet="currentComponent?.component; inputs: currentComponent?.inputs" class=" overflow-y-auto">
                    </ng-container> -->
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="tabPosition == 'Left'"
        class="flex h-full flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden">

        <mat-drawer-container class="flex-auto sm:h-full">
            <!-- Drawer -->
            <mat-drawer class="sm:w-96 dark:bg-gray-900"  [ngClass]="getDrawerClass()" [autoFocus]="false" [mode]="drawerMode"
                [opened]="drawerOpened && !hideTabs" #drawer>
                <!-- Header -->
                <div class="flex items-center justify-center  m-auto  ">
                    <div class="flex">
                        <button mat-icon-button class="lg:hidden" (click)="drawer.close()" [matTooltip]="'Toggle'">
                            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                        </button>
                        <div class="flex cursor-pointer"  (click)="closeDialog()">
                            <button  mat-flat-button class="hover:bg-primary-100 rounded-md"  [matTooltip]="'Exit'">
                                <mat-icon >keyboard_return</mat-icon>
                                    <div *ngIf="tabProperties !== 'type4'" class="hover:font-semibold m-auto">
                                        Back To {{ tabsData[0]?.name }}
                                    </div>
                            </button>
                            
                        </div>
                        

                    </div>
                </div>
                  <!-- Panel links -->
            <div class="flex flex-col divide-y border-t border-b">
                <ng-container *ngFor="let tab of tabsData; trackBy: trackByFn">
                    <!-- <div
                        class="flex px-8 py-5 cursor-pointer shadow-md"
                        [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedTab || selectedTab !== tab.id,
                                        'bg-primary-50 dark:bg-hover': selectedTab && selectedTab === tab?.id}"
                            (click)="goToTab(tab)">
                        <mat-icon [ngClass]="{'text-hint': !selectedTab || selectedTab !== tab?.id,
                                            'text-primary dark:text-primary-500': selectedTab && selectedTab === tab.id}"
                                            [svgIcon]="tab?.icon"></mat-icon>
                        <div class="ml-3">
                            <div
                                class="font-medium leading-6"
                                [ngClass]="{'text-primary dark:text-primary-500': selectedPanel && selectedPanel === panel.id}">
                                {{tab?.name}}
                            </div>
                            <div class="mt-0.5  text-sm">
                                {{tab?.description}}
                            </div>
                        </div>
                    </div> -->
                    <div *ngIf="tabProperties === 'type4'; else fullTab"
                    class="flex py-5 custom-pb cursor-pointer  shadow-md btn border btn-primary z-10 tab-button "
                    [ngClass]="{'bg-primary-500 ': selectedTab && selectedTab === tab?.id,
                    'bg-primary-50 dark:bg-hover': selectedTab && selectedTab === tab?.id }"
                            (click)="goToTab(tab)" >
                        <mat-icon  class="tab-icon" 
                        [ngClass]="{'text-white': selectedTab === tab?.id,
                        'text-default': selectedTab !== tab?.id}"
                                            [svgIcon]="tab?.icon" [matTooltip]= tab?.name></mat-icon>
                </div>
                <ng-template #fullTab>
                    <div class="flex px-8 py-5 cursor-pointer shadow-md"
                       [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedTab || selectedTab !== tab.id,
                                        'bg-primary-50 dark:bg-hover': selectedTab && selectedTab === tab?.id}"
                            (click)="goToTab(tab)">
                        <mat-icon [ngClass]="{'text-hint': !selectedTab || selectedTab !== tab?.id,
                                            'text-primary dark:text-primary-500': selectedTab && selectedTab === tab.id}"
                                            [svgIcon]="tab?.icon"></mat-icon>
                        <div class="ml-3">
                            <div class="font-medium leading-6"
                                [ngClass]="{
                                    'text-primary dark:text-primary-500': selectedPanel && selectedPanel === panel.id }">
                                {{tab?.name}}
                            </div>
                            <div class="mt-0.5 text-sm">
                                {{tab?.description}}
                            </div>
                        </div>
                    </div>
                </ng-template>
                </ng-container>
            </div>
            </mat-drawer>

            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col bg-white">
                <!-- Main -->
                <div class="flex-auto px-8 pt-9 pb-3 md:p-4 lg:p-6 bg-white">
                    <!-- Panel header -->
                    <div class="flex items-center position-absolute z-20 pb-3 md:pt-3 -mt-8">
                        <button mat-icon-button (click)="closeDialog()" [matTooltip]="'Exit'" *ngIf="hideTabs">
                            <mat-icon [svgIcon]="'heroicons_solid:arrow-left'"></mat-icon>
                        </button>
                        <!-- Drawer toggle -->
                        <button class="lg:hidden -ml-2 button1" mat-icon-button (click)="drawer.toggle()">
                            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                        </button>
                        <div class="lg:hidden flex cursor-pointer" (click)="closeDialog()">
                            <button mat-flat-button class="hover:bg-primary-100 rounded-md"  [matTooltip]="'Exit'">
                                <mat-icon >keyboard_return</mat-icon>
                                    <div class="hover:font-semibold m-auto">
                                        Back To {{ tabsData[0]?.name }}1
                                    </div>
                            </button>
                            
                        </div>
                    </div>

                    <!-- Load selected panel component -->
                    <div class="menu-component-ref">
                        <ng-container *ngFor="let tab of tabsData">
                            <div [ngClass]="{'hidden': !tab?.isActive}">
                                <ng-container *ngComponentOutlet="tab?.component?.component; inputs: tab?.componentInputs">
                                </ng-container>
                            </div>
                        </ng-container>
                        <!-- <ng-container
                            *ngComponentOutlet="currentComponent?.component; inputs: currentComponent?.inputs">
                        </ng-container> -->
                    </div>

                </div>

            </mat-drawer-content>

        </mat-drawer-container>

    </div>

    <!-- <div *ngIf="data?.show_approval"  class="overflow-y-hidden pt-2 relative z-10">
        <app-approval [data]="data" (dataEvent)="receiveData($event)"></app-approval>
    </div> -->
</div>