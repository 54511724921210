import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AuthService } from 'app/core/auth/auth.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { SharedModule } from 'app/shared/shared.module';
import { lastValueFrom } from 'rxjs';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';

@Component({
    selector: 'app-forms-conversation',
    templateUrl: './forms-conversation.component.html',
    styleUrls: ['./forms-conversation.component.scss'],
    standalone: true,
    imports: [SharedModule, PickerComponent, NgxAudioPlayerModule],
})
export class FormsConversationComponent implements OnInit, OnChanges {
    @ViewChild('editTemplate')
    editTemplate: TemplateRef<any>;
    dialogRef: MatDialogRef<any>;
    @ViewChild('messageInput') messageInput!: ElementRef;
    @ViewChild('imageInput') imageInput: ElementRef;
    @ViewChild('editInput') editInput!: ElementRef;
    @Input() data: any;

    conversations = [];
    enableMessageSelection: boolean;
    selectedImages: File[] = [];
    showDrawer: boolean = false;
    editmessage;

    private userInfo = this._authService.userInfo;
    @ViewChild('waveformCanvas') waveformCanvas!: ElementRef<HTMLCanvasElement>;
    private audioContext!: AudioContext;
    private analyser!: AnalyserNode;
    private microphone!: MediaStreamAudioSourceNode;
    private recording = false;
    showEmojiPicker: boolean = false;
    isRecording = false;
    mediaRecorder: any;
    audioChunks: any[] = [];
    audioUrl: string | undefined;
    image: any;
    base64Audio: string;
    bars = new Array(80);
    messageId: any;
    editDrawer: boolean;
    recordingTime: string = '0:00';
    private recordingInterval: any;
    private seconds: number = 0;
    audioDuration: string = '0:00';

    constructor(
        private _commonService: CommonService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _snackbar: SnackbarService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _authService: AuthService,
        private dialog: MatDialog,
    ) { }

    get selectedMessages() {
        return this.conversations.filter((e) => e?.isSelected);
    }

    ngOnInit() {
        if (this.data?.title) {
            this.data.title = this.data?.title.replace(/^Edit /, '').trim();
            this._changeDetectorRef.detectChanges();
        }
        this.getConversation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('data' in changes) {
            if (this.data?.title) {
                this.data.title = this.data?.title
                    .replace(/^Edit /, '')
                    .trim();
                this._changeDetectorRef.detectChanges();
            }
            this.getConversation();
        }
    }

    toggleEmojiPicker() {
        this.showEmojiPicker = !this.showEmojiPicker;
    }

    addEmoji(event) {
        const emoji = event.emoji.native;
        this.messageInput.nativeElement.value += emoji;
    }

    startRecording() {
        this.seconds = 0;
        this.updateRecordingTime();
        
        if (this.recordingInterval) {
            clearInterval(this.recordingInterval);
        }
    
        this.recordingInterval = setInterval(() => {
            this.seconds++;
            this.updateRecordingTime();
            this._changeDetectorRef.detectChanges();
        }, 1000);

        this.isRecording = true;

        // Access the user's microphone
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                this.mediaRecorder = new MediaRecorder(stream);
                this.mediaRecorder.start();

                this.mediaRecorder.addEventListener('dataavailable', (event: any) => {
                    this.audioChunks.push(event.data);
                });


            })
    }

    stopRecording() {
        if (this.recordingInterval) {
            clearInterval(this.recordingInterval);
            this.recordingInterval = null;
        }
        
        this.recordingTime = '0:00';
        this.isRecording = false;

        this.mediaRecorder.addEventListener('stop', async () => {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
            let audioUrl = URL.createObjectURL(audioBlob);
            this.audioChunks = [];
            this.base64Audio = await this.convertBlobToBase64(audioBlob);
            this.sendMessage();
        });
        this.mediaRecorder.stop();
        this._changeDetectorRef.detectChanges();
    }

    private updateRecordingTime() {
        const minutes = Math.floor(this.seconds / 60);
        const seconds = this.seconds % 60;
        this.recordingTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    updateAudioDuration(event: Event) {
        const audioElement = event.target as HTMLAudioElement;
        const totalSeconds = Math.floor(audioElement.duration);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        this.audioDuration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    convertBlobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    removeVoice() {
        this.isRecording = false;
        this.base64Audio = '';
        this.mediaRecorder.stop();
        this._changeDetectorRef.detectChanges();
    }

    getConversation() {
        if (
            (this.data?.form_id && this.data?.reference_id) ||
            (this.data.reference_type === 'module' &&
                this.data?.reference_type &&
                this.data?.reference_type_id)
        ) {
            const filter = {};
            if (this.data.reference_type === 'form') {
                filter['form_id'] = this.data?.form_id;
                filter['reference_id'] = this.data?.reference_id;
            } else if (this.data.reference_type === 'module') {
                filter['reference_type'] = this.data?.reference_type;
                filter['reference_type_id'] = this.data?.reference_type_id;
            } else {
                filter['form_id'] = this.data?.form_id;
                filter['reference_id'] = this.data?.reference_id;
            }
            this._commonService
                .getDataByFields(MODELS_CONSTANTS.FORM_CONVERSATIONS, filter)
                .subscribe((response: IResponse<any>) => {
                    if (response?.status === 200) {
                        this.conversations = response?.data?.map((e) => {
                            if (e?.sender_user_id === this.userInfo?._id) {
                                e['isMine'] = true;
                            } else {
                                e['isMine'] = false;
                            }

                            return e;
                        });
                    }
                });
        }
    }

    editMessage(conversation): void {
        this.messageId = conversation?._id;
        this.editInput.nativeElement.value = conversation?.message;
        this.editmessage = conversation?.message;
        this.image = conversation?.image;
        this.editDrawer = true;
        this._changeDetectorRef.detectChanges();
    }

    sendMessage() {
        // const message = inputEl?.value;
        let requestBody = {
            form_id: this.data?.form_id || null,
            reference_id: this.data?.reference_id || null,
            reference_type: this.data?.reference_type || null,
            reference_type_id: this.data?.reference_type_id || null,
            sender_user_id: this.userInfo?._id || null,
            sender_user_name: this.userInfo?.name || null,
            message: this.messageInput.nativeElement.value ? this.messageInput.nativeElement.value : this.imageInput?.nativeElement.value,
            audioUrl: this.base64Audio ? this.base64Audio : null,
            image: this.image ? this.image : null,
            _id: this.messageId && this.editInput.nativeElement.value ? this.messageId : '' 
        };
        if(!this.messageId && !this.editInput.nativeElement.value) {
            delete requestBody._id;
        }
        if(this.messageId && this.editInput.nativeElement.value) {
            requestBody.message = this.editInput.nativeElement.value;
        }

        this._commonService
            .saveRecord(MODELS_CONSTANTS.FORM_CONVERSATIONS, requestBody)
            .subscribe((response: IResponse<any>) => {
                if (response?.status === 200) {
                    response.data['isMine'] = true;
                    this.conversations.push(response?.data);
                    this.base64Audio = '';
                    this.messageId ? this._snackbar.success(`Message edited successfully`) : '';
                    this.messageId ? this.getConversation() : '';
                    this.messageInput.nativeElement.value = '';
                    this.imageInput.nativeElement.value = '';
                    this.editInput.nativeElement.value = '';
                    this.messageId = '';
                    this.closeDrawer();
                }
            });
            this.showEmojiPicker = false;
    }
    closeEmojiPicker() {
        this.showEmojiPicker = false; 
      }
    /**
     * select a message
     */
    selectMessage(message) {
        if (this.enableMessageSelection) {
            message['isSelected'] = !message?.['isSelected'];
        }
    }

    /**
     * change message selection flag
     */
    onMessageSelectionFlagChange() {
        this.enableMessageSelection = !this.enableMessageSelection;
        if (!this.enableMessageSelection) {
            this.conversations?.forEach((e) => {
                e['isSelected'] = false;
            });
        }
    }

    /**
     * Clear messages
     */
    clearMessages() {
        const selectedMessages = this.selectedMessages;
        const confirmation = this._fuseConfirmationService.open({
            title: 'Clear messages',
            message:
                'Are you sure you want to clear all the selected messages from this chat? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Clear',
                },
            },
        });

        confirmation.afterClosed().subscribe(async (result) => {
            if (result === 'confirmed') {
                const selectedMessageIds = selectedMessages?.map((e) => e?._id);
                const success = await this.deleteSelectedMessages(
                    selectedMessageIds
                );
                if (success) {
                    this._snackbar.success(
                        'All the selected messages are cleared successfully'
                    );
                    this.getConversation();
                    this.enableMessageSelection = false;
                } else {
                    this._snackbar.error(
                        'Something went wrong while deleting selected messages, please try again later'
                    );
                }
            }
        });
    }

    /**
     * Delete selected messages
     */
    async deleteSelectedMessages(ids): Promise<boolean> {
        for (let [index, id] of ids.entries()) {
            let success = true;
            const response$ = this._commonService.deleteRecordsById(
                MODELS_CONSTANTS?.FORM_CONVERSATIONS,
                id
            );
            const result = await lastValueFrom(response$);
            if (result?.status !== 200) {
                success = false;
            }

            if (index === ids?.length - 1) {
                return success;
            }
        }
    }

    fileAttachment(event: Event) {
        const fileList = (event.target as HTMLInputElement).files;
        if (fileList) {
            const files = Array.from(fileList);
            for (let file of files) {
                if (file.size > 10485760) {
                    alert('File size is more than 10 MB');
                    return;
                }
                if (file.type.startsWith('image/')) {
                    this.selectedImages.push(file);
                }
            }
            this.showDrawer = true;
        }
    }

    uploadAvatar(fileList: FileList): void {
        if (!fileList?.length) {
            return;
        }
        this._commonService.uploadImage(fileList, 'FORM_CONVERSATION').subscribe({
            next: (response: any) => {
                if (response?.url) {
                    this.image = response?.url;
                    this.showDrawer = true;
                    this._snackbar.success(`Image added successfully`);
                    this._changeDetectorRef.markForCheck();
                } else {
                    this._snackbar.error(response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                }
            },
            error: (err) => {
                this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            },
        });
    }

    removeImage() {
        this.image = "";
        this._changeDetectorRef.markForCheck();
    }

    getImageUrl(file: File): string {
        return this.image;
        // return URL.createObjectURL(file);
    }

    closeDrawer() {
        this.showDrawer = false;
        this.editDrawer = false;
        this.removeImage();
        this.messageId ? this.getConversation() : '';
        this.messageInput.nativeElement.value = '';
        this.imageInput.nativeElement.value = '';
        this.editInput.nativeElement.value = '';
        this.messageId = '';
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
    isImage(fileUrl: string): boolean {
        return /\.(jpg|jpeg|png|gif)$/i.test(fileUrl);
      }
      
      isPdf(fileUrl: string): boolean {
        return /\.pdf$/i.test(fileUrl);
      }
      
      isExcel(fileUrl: string): boolean {
        return /\.(xls|xlsx)$/i.test(fileUrl);
      }
      getFileName(fileUrl: string): string {
        return fileUrl ? fileUrl.split('_').pop() || 'Download File' : 'Download File';
    }

    deleteMessage(conversation): void {
    
        const confirmation = this._fuseConfirmationService.open({
            title: 'Delete message',
            message: 'Are you sure you want to delete this message? This action cannot be undone!',
            actions: {
                confirm: { label: 'Delete' },
            },
        });
    
        confirmation.afterClosed().subscribe(async (result) => {
            if (result === 'confirmed') {
                const response$ = this._commonService.deleteRecordsById(MODELS_CONSTANTS.FORM_CONVERSATIONS, conversation._id);
                const result = await lastValueFrom(response$);
    
                if (result?.status === 200) {
                    this.conversations = this.conversations.filter((msg) => msg._id !== conversation._id);
                    this._snackbar.success('Message deleted successfully.');
                } else {
                    this._snackbar.error('Failed to delete the message. Please try again later.');
                }
            }
        });
    }
}
