import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AuthService } from 'app/core/auth/auth.service';
import { Message } from 'app/layout/common/messages/messages.types';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { Subject, forkJoin, lastValueFrom, takeUntil } from 'rxjs';
import { MessagesService } from './messages.service';
import { SocketService } from 'app/shared/services/socket/socket.service';

@Component({
    selector: 'messages',
    templateUrl: './messages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'messages',
    standalone: true,
    imports: [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe],
})
export class MessagesComponent implements OnInit, OnDestroy {
    @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
    @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

    messages: Message[] = [];
    // messages: any;
    unreadCount: number = 0;
    user: any = {};
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    contacts: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _commonService: CommonService,
        private _messagesService: MessagesService,
        private _snackbar: SnackbarService,
        private _authService: AuthService,
        private _router: Router,
        private _fuseConfirmationService: FuseConfirmationService,
        private _socketService: SocketService
    ) {
        this._socketService.connect();
        this._socketService.receiveMessage((data) => {
            this.ngOnInit();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Fetch messages explicitly
        this._messagesService.getAll();

        // Subscribe to messages observable
        this.getAllMessages();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    getAllMessages(): void {
        this._messagesService.messages$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((messages: Message[]) => {
                // Load the messages
                this.messages = messages;

                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    redirectToChat(message): void {
        if (message?.chat_id) {
            this._router.navigate([`chat/${message.chat_id}`]).then(success => {
                if (success) {
                    this.markAsRead(message);
                    this.closePanel();
                }
            });
        }
    }

    markAllAsRead(): void {
        const unreadMessages = this.messages.filter(message => !message.read);
        if (unreadMessages.length) {
            const updateRequests = unreadMessages.map((message) =>
                this._messagesService.updateMessage(message, { read: true })
            );
            forkJoin(updateRequests).subscribe(() => {
                this.getAllMessages();
                this._calculateUnreadCount();
                this._changeDetectorRef.detectChanges();
            });
        }
    }

    removeAllMessages(): void {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Remove All Messages',
            message: 'Are you sure you want to remove all messages? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Remove All',
                    color: 'warn',
                },
                cancel: {
                    label: 'Cancel'
                }
            }
        });

        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                // Create an array of observables for removing all messages
                const removeRequests = this.messages.map((message) =>
                    this._messagesService.updateMessage(message, { is_remove: true })
                );

                forkJoin(removeRequests).subscribe({
                    next: () => {
                        this._snackbar.success('All messages removed successfully');
                        this.getAllMessages();  // Refresh the messages
                        this._calculateUnreadCount();  // Recalculate unread count
                        this._changeDetectorRef.detectChanges();  // Trigger change detection
                    },
                    error: (err) => {
                        console.error('Error removing messages', err);
                    }
                });
            }
        });
    }

    markAsRead(message): void {
        // Mark as read (update 'read' field)
        this._messagesService.updateMessage(message, { read: true }).subscribe(response => {
            if (response?.status === 200) {
                this.getAllMessages();
            }
            this._changeDetectorRef.detectChanges();
        });
    }

    toggleRead(message): void {
        // Toggle the read status (invert 'read' field)
        this._messagesService.updateMessage(message, { read: !message.read }).subscribe(response => {
            if (response?.status === 200) {
                this.getAllMessages();
            }
            this._changeDetectorRef.detectChanges();
        });
    }

    removeMessage(message): void {
        // Mark the message as removed (update 'is_remove' field)
        this._messagesService.updateMessage(message, { is_remove: true }).subscribe(response => {
            if (response?.status === 200) {
                this.getAllMessages();
            }
            this._changeDetectorRef.detectChanges();
        });
    }

    openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._messagesPanel || !this._messagesOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Get all the messages
        this.getAllMessages();

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._messagesPanel, this._viewContainerRef));
    }

    closePanel(): void {
        this._overlayRef.detach();
    }

    // async markAllAsRead() {
    //     const unreadMessages = this.messages.filter((e) => !e.read);
    //     for (let [index, message] of unreadMessages.entries()) {
    //         message.read = true;
    //         const response$ = this._commonService.saveRecord(
    //             MODELS_CONSTANTS.MESSAGES,
    //             message
    //         );
    //         await lastValueFrom(response$);
    //         if (index === this?.messages?.length - 1) {
    //             this._snackbar.success('Marked all messages as read');
    //             this._calculateUnreadCount();
    //             this._changeDetectorRef.detectChanges();
    //         }
    //     }
    //     // this._messagesService.markAllAsRead().subscribe();
    // }

    // /**
    //  * Toggle read status of the given message
    //  */
    // toggleRead(message: Message): void {
    //     message.read = !message.read;

    //     this._commonService
    //         .saveRecord(MODELS_CONSTANTS.MESSAGES, message)
    //         .subscribe({
    //             next: (response: any) => {
    //                 if (response?.status === 200) {
    //                     this._snackbar.success('Message updated successfully');
    //                     this._calculateUnreadCount();
    //                     this._changeDetectorRef.detectChanges();
    //                 } else {
    //                     this._snackbar.error(response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
    //                 }
    //             },
    //             error: (err) => {
    //                 console.error(err);
    //                 this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
    //             },
    //         });
    //     // this._messagesService.update(message.id, message).subscribe();
    // }

    // delete(message: Message): void {
    //     this._commonService
    //         .deleteRecordsById(MODELS_CONSTANTS.MESSAGES, message?._id)
    //         .subscribe({
    //             next: (response) => {
    //                 if (response?.status === 200) {
    //                     const index = this.messages.findIndex(
    //                         (e) => e?._id === message?._id
    //                     );
    //                     if (index !== -1) {
    //                         this.messages.splice(index, 1);
    //                         this._calculateUnreadCount();
    //                         this._changeDetectorRef.detectChanges();
    //                     }
    //                     this._snackbar.success('Message deleted successfully');
    //                 } else {
    //                     this._snackbar.error(
    //                         response?.message ||
    //                             MESSAGE_CONSTANTS.TRY_AGAIN_LATER
    //                     );
    //                 }
    //             },
    //             error: (err) => {
    //                 console.error(err);
    //                 this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
    //             },
    //         });
    //     // this._messagesService.delete(message.id).subscribe();
    // }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.messages && this.messages.length) {
            count = this.messages.filter(message => !message.read).length;
        }

        this.unreadCount = count;
        this._changeDetectorRef.markForCheck();
    }
}
