<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden p-2 bg-gray-30">
    <!-- Header -->
    <div class="flex items-center justify-between h-20 pl-8 sm:pl-8 text-on-primary bg-card shadow-md 
            border fixed z-[1] md:pr-16 mr-8 border-x-0" style=" width: -webkit-fill-available;">
        <div class="text-3xl text-black font-bold">Task</div>
        <!-- <div class="ml-auto" [ngClass]="matDialogRef ? 'p-6' : 'p-2'">
                <button mat-flat-button color="primary" (click)="saveCard()">Save</button>
            </div>
            <button *ngIf="matDialogRef" mat-icon-button (click)="matDialogRef.close()" [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button> -->

        <div class="w-full flex justify-end">
            <div class="ml-auto" [ngClass]="matDialogRef ? 'p-6' : 'p-2 pr-6 mr-4'">
                <button mat-flat-button color="primary" (click)="saveCard()">Save</button>
            </div>
            <button *ngIf="matDialogRef" mat-icon-button (click)="matDialogRef.close()" [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
    </div>

    <!-- Card form -->
    <mat-dialog-content class="p-0 mt-18 w-full h-full overflow-y-auto">
        <form class="sm:p-4 gap-4 md:divide-x" [formGroup]="cardForm">
            <div class="flex flex-col md:flex-row w-full">

                <div class="leftcontent flex flex-col md:flex-row  w-full gap-4 p-2 mb-12">
                    <!-- Left Content -->
                    <div class="gap-4 md:w-1/2 w-full flex flex-col h-fit">
                        <!-- Title -->
                        <mat-form-field class="fuse-mat-dense col-12" [subscriptSizing]="'dynamic'">
                            <mat-label>Title</mat-label>
                            <textarea matInput [formControlName]="'title'" [rows]="1" cdkTextareaAutosize
                                [cdkAutosizeMinRows]="1">
                                </textarea>
                        </mat-form-field>

                        <!-- Description -->
                        <mat-form-field class="fuse-mat-dense col-12" [subscriptSizing]="'dynamic'">
                            <mat-label>Description</mat-label>
                            <textarea matInput [formControlName]="'description'" [rows]="1" cdkTextareaAutosize
                                [cdkAutosizeMinRows]="1">
                        </textarea>
                        </mat-form-field>

                        <div class="flex flex-col md:flex-row col-12 gap-2 justify-between ">
                            <!-- Assign To -->
                            <mat-form-field class="fuse-mat-dense md:col-6  w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Assign To</mat-label>
                                <mat-select placeholder="Assign To" [formControlName]="'asignTo'">
                                    <mat-option *ngFor="let contact of contactList" [value]="contact?._id">
                                        {{contact?.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Due date -->
                            <div class="md:col-6 w-full">
                                <div class="font-medium">Due date</div>
                                <div class="relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer"
                                    [ngClass]="{'text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700': !dueDate,
                                        'text-green-800 bg-green-200 dark:text-green-100 dark:bg-green-500': dueDate && !isOverdue(dueDate),
                                        'text-red-800 bg-red-200 dark:text-red-100 dark:bg-red-500': dueDate && isOverdue(dueDate)}"
                                    (click)="dueDatePicker.open()">
                                    <mat-icon class="icon-size-5 text-current"
                                        [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                                    <span class="ml-2 text-md font-medium">
                                        <ng-container *ngIf="dueDate">{{dueDate | date:'longDate'}}</ng-container>
                                        <ng-container *ngIf="!dueDate">Not set</ng-container>
                                    </span>
                                    <mat-form-field
                                        class="fuse-mat-dense invisible absolute inset-0 -mt-2.5 opacity-0 pointer-events-none"
                                        [subscriptSizing]="'dynamic'">
                                        <input matInput [formControlName]="'dueDate'" [matDatepicker]="dueDatePicker">
                                        <mat-datepicker #dueDatePicker>
                                            <mat-datepicker-actions>
                                                <button mat-button (click)="cardForm.get('dueDate').setValue(null)"
                                                    matDatepickerCancel>
                                                    Clear
                                                </button>
                                                <button mat-flat-button [color]="'primary'" matDatepickerApply>
                                                    Select
                                                </button>
                                            </mat-datepicker-actions>
                                        </mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <!-- Labels -->
                        <div class="w-full col-12">
                            <div class="font-medium">Labels</div>
                            <div class="mt-1 rounded-md border border-gray-300 shadow-sm overflow-hidden">
                                <!-- Header -->
                                <div class="flex items-center my-1 mx-3">
                                    <div class="flex items-center flex-auto min-w-0">
                                        <mat-icon class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                        <input class="min-w-0 ml-2 py-1 border-0 fuse-mat-dense" type="text"
                                            placeholder="Enter label name" (input)="filterLabels($event)"
                                            (keydown)="filterLabelsInputKeyDown($event, labelInput?.value)"
                                            [maxLength]="50" #labelInput>
                                    </div>
                                </div>
                                <!-- Available labels -->
                                <div class="max-h-40 leading-none overflow-y-auto border-t">
                                    <!-- Labels -->
                                    <ng-container *ngFor="let label of filteredLabels; trackBy: trackByFn">
                                        <mat-checkbox class="flex items-center h-10 min-h-10 pl-1 pr-4"
                                            [color]="'primary'" [checked]="hasLabel(label)"
                                            (change)="toggleProductTag(label, $event)">
                                            {{label}}
                                        </mat-checkbox>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <!-- Stage -->
                        <mat-form-field class="fuse-mat-dense col-12" [subscriptSizing]="'dynamic'"
                            style="height: 8px;">
                            <mat-label>Stage</mat-label>
                            <mat-select placeholder="Assign To" [formControlName]="'stage_id'">
                                <mat-option *ngFor="let stage of board_stages" [value]="stage?._id">
                                    {{stage?.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Right Content -->
                    <div class="rightcontent flex flex-col md:w-1/2 w-full gap-4 p-2 overflow-y-auto max-h-96">
                        <div class="flex h-fit w-full">
                            <mat-form-field class="fuse-mat-dense w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Add Subtasks</mat-label>
                                <input matInput [formControlName]="'subtasks'">
                            </mat-form-field>
                            <mat-icon matTooltip="Add Subtask" class="m-auto mt-8 ml-2">add_circle</mat-icon>
                        </div>


                        <div class="h-fit w-full">
                            <mat-label class="font-semibold">Subtasks</mat-label>
                            <ng-container *ngFor="let stage of board_stages">
                                <ng-container *ngFor="let task of stage.tasks ">

                                    <div *ngIf="task.subtasks && task.subtasks.length > 0">
                                        <div class="flex gap-2 rounded-md p-4 bg-card border shadow-md"
                                            [ngClass]="subtaskdiv ? 'w-full' : 'w-3/4'">
                                            <div class="text-center text-white leading-8 bg-primary rounded-md"
                                                (click)="subtaskdiv = !subtaskdiv" [ngClass]="{'w-3/4': subtaskdiv,
                                            'w-full': !subtaskdiv,
                                            'line-through': checksubtask}">
                                                {{ task.subtasks }}
                                            </div>
                                            <div *ngIf="subtaskdiv" class="flex w-1/4 gap-2 m-auto justify-center">
                                                <mat-icon *ngIf="!checksubtask" class="w-5 h-5 text-green-500"
                                                    matTooltip="Completed"
                                                    (click)="checksubtask = !checksubtask">check_circle</mat-icon>
                                                <mat-icon *ngIf="checksubtask" class="w-5 h-5 text-red-500"
                                                    (click)="checksubtask = !checksubtask"
                                                    matTooltip="Incomplete">close</mat-icon>
                                                <mat-icon class="w-5 h-5" matTooltip="Delete">delete</mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>

                    </div>
                </div>


            </div>
        </form>
    </mat-dialog-content>



</div>