import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [],
    imports: [],
    exports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule, MatProgressSpinnerModule],
})
export class SharedModule {}
