import { Overlay } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, inject, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations/public-api';
import { debounceTime, filter, map, Subject, takeUntil, throwIfEmpty } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonService } from 'app/shared/services/common/common.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { AuthService } from 'app/core/auth/auth.service';


@Component({
    selector     : 'search',
    templateUrl  : './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'fuseSearch',
    animations   : fuseAnimations,
    standalone   : true,
    imports      : [NgIf, MatButtonModule, MatIconModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, MatOptionModule, NgFor, RouterLink, NgTemplateOutlet, MatFormFieldModule, MatInputModule, NgClass, MatTooltipModule],
    providers    : [
        {
            provide   : MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: () =>
            {
                const overlay = inject(Overlay);
                return () => overlay.scrollStrategies.block();
            },
        },
    ],
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy
{
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 1;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    opened: boolean = false;
    resultSets: any[];
    imageUrl: string = 'https://placehold.co/600x400.png';

    searchControl: UntypedFormControl = new UntypedFormControl();
    private _matAutocomplete: MatAutocomplete;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    menus: any;
    menuRoles: any;
    userInfo: any;

    /**
     * Constructor
     */
    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2,
        private _changeDetectorRef: ChangeDetectorRef,
        private _commonService: CommonService,
        private router: Router,
        private _authservice: AuthService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any
    {
        return {
            'search-appearance-bar'  : this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened'          : this.opened,
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef)
    {
        // If the value exists, it means that the search input
        // is now in the DOM, and we can focus on the input..
        if ( value )
        {
            // Give Angular time to complete the change detection cycle
            setTimeout(() =>
            {
                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    /**
     * Setter for mat-autocomplete element reference
     *
     * @param value
     */
    @ViewChild('matAutocomplete')
    set matAutocomplete(value: MatAutocomplete)
    {
        this._matAutocomplete = value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Appearance
        if ( 'appearance' in changes )
        {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.userInfo = this._authservice.userInfo;
        this.getAllMenus();
        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) =>
                {
                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if ( !value || value.length < this.minLength )
                    {
                        this.resultSets = null;
                        return null;
                    }

                    // Continue
                    return value;
                }),

            )
            .subscribe((value) =>
            {
                if (value) {
                this.resultSets = this.menus.filter(menu => menu.menu_name.toLowerCase().includes(value.toLowerCase())).map(menu => ({
                    label: menu.menu_name, 
                    value: menu.menu_id,
                    // link: menu.menu_path + '/' + (menu.menu_path === '/core/list' ? menu.ref_id : ''),
                    link: menu.menu_path + '/' + (menu.menu_path === '/core/list' || menu.menu_path === 'core/list' || menu.menu_path === 'core/dashboard' || menu.menu_path === 'core/content-management' ? menu.ref_id : ''),
                    parent_menu: menu.parent_menu, 
                    menu_icon:menu.menu_icon
                }));

              

                this.search.next(this.resultSets);
                console.log('result', this.resultSets);
                console.log('result',this.resultSets.length)
                }
            });
    }


    onResultClick(link: string): void {
        // Clear the search box
        this.searchControl.setValue('');
    
        // Navigate to the selected result's link
        this.router.navigate([link]);
        this.close();
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void
    {
        // Escape
        if ( event.code === 'Escape' )
        {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
            if ( this.appearance === 'bar' && !this._matAutocomplete.isOpen )
            {
                this.close();
            }
        }
    }

    getAllMenus() {
        this._commonService
      .getAllData(MODELS_CONSTANTS.MENUS)
      .subscribe({
        next: (response: any) => {
          if (response?.status === 200) {
            let allMenus = response?.data;
            this.menus = response?.data;
            // this.menus = this.menus.filter((element: { menu_parent_id: any; }) => element.menu_parent_id);
            this.menus.forEach((menu, index) => {
                let parent = allMenus.find((item => item._id === menu.menu_parent_id));
                parent ? menu.parent_menu = parent.menu_name : menu.parent_menu = null;
            });
            let filteredChild = this.menus;
            filteredChild.forEach((element, index) => {
                if (!element.menu_parent_id) { // if it's a parent menu
                    let childMenus = allMenus.filter(item => item.menu_parent_id === element._id);
                    if (childMenus.length) {
                        this.menus.splice(index, 1);
                    }
                }
            });
            this.getAllMenuRoles();
            this._changeDetectorRef.markForCheck();
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
    }

    getAllMenuRoles() {
        this._commonService
      .getAllData(MODELS_CONSTANTS.MENU_ROLES)
      .subscribe({
        next: (response: any) => {
          if (response?.status === 200) {
            this.menuRoles = response?.data;
            let allMenus = this.menus;
            this.menus = [];
            allMenus.forEach(element => {
                let response = this.menuRoles.find(item => item.menu_id == element._id && item.role_id == this.userInfo.role_id)     
                if(response) {
                    this.menus.push(element);
                }           
            });
            console.log('this.menus',this.menus)
            this._changeDetectorRef.markForCheck();
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void
    {
        // Return if it's already opened
        if ( this.opened )
        {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void
    {
        // Return if it's already closed
        if ( !this.opened )
        {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }
}
